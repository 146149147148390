import React from "react"
import AppointmentsTable from "../../components/AppointmentsTable/AppointmentsTableAgent"

const AgentInfo = ({ user }) => {
  if (!user?.username) return <p>Error fetching agent info</p>

  return (
    <div>
      <AppointmentsTable user={user} />
    </div>
  )
}
export default AgentInfo
