import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import { AppContainer } from '../../styles'
import Login from '../LoginPage/Login'
import ClientInfo from './ClientInfo'
import AgentInfo from './AgentInfo'
import CookieConsent from 'react-cookie-consent'
import AdminInfo from './AdminInfo'
import { Content, Footer } from 'antd/lib/layout/layout'
import { Layout } from 'antd'
import { useUserInfo } from '../../fetchHooks/useFetchHooks'
import MasterInfo from './MasterInfo'
import moment from 'moment-timezone'

const Home = () => {
  const { data: user } = useUserInfo()

  // const [cookies, setCookie, removeCookie] = useCookies(['salesplanr-login'])
  // user && console.log('Home user: ', user)

  React.useEffect(() => {
    console.log('Home user: ', user)
  }, [user])

  // const now = Date.now()
  // console.log('moment() ', moment())
  // console.log('new date() ', now)
  // console.log('Intl: ', new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(now))

  // const userType =
  //   cookies.userData?.role !== undefined &&
  //   (cookies.userData?.role === 'AGENT' ? <AgentInfo user={cookies.userData} /> : <ClientInfo user={cookies.userData} />)
  // const userType =
  //   user?.role !== undefined &&
  //   (user?.role === 'AGENT' ? <AgentInfo user={user} /> : user?.role === 'ADMIN' ? <AdminInfo user={user} /> : <ClientInfo user={user} />)

  const renderUserInfo = () => {
    switch (user?.role) {
      case 'MASTER':
        return <MasterInfo user={user} />

      case 'AGENT':
        return <AgentInfo user={user} />

      case 'ADMIN':
        return <AdminInfo user={user} />

      case 'CLIENT':
        return <ClientInfo user={user} />

      default:
        return <p>Some error occured...</p>
    }
  }

  // if (!userType) return <Login />
  return (
    // <>
    <AppContainer>
      <Layout className="layout-default">
        <Navbar hidden={!user?.role} />

        <Content className="layout-default" style={{ margin: '24px 16px 0' }}>
          {!user ? <Login /> : renderUserInfo()}
        </Content>
        <Footer className="layout-default" style={{ textAlign: 'center', marginTop: '8rem', color: 'white' }}>
          SalesPlanr ©2022 by WeTech AS
        </Footer>
        <CookieConsent
          location="bottom"
          // buttonText="Sure man!!"
          cookieName="SalesPlanrCookieConsent"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Layout>
    </AppContainer>
    // </>
  )
}

export default Home
