import Modal from 'antd/lib/modal/Modal'
import { useState } from 'react'
import { useAnswersByAppointmentId, useAppointmentsInfoById, useUserInfo } from '../../fetchHooks/useFetchHooks'
import { Form, Radio, Empty, Spin, Alert, Select, Rate } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import { ContentScreenMiddleFixed, StyledButton, StyledInfoField, StyledLabel, StyledTextArea } from '../../styles'
import { isMobile } from '../Navbar/Navbar'
import { ModalButtons } from '../CreateAgent/CreateAgentForm'
import './AppointmentDetails.scss'
import { format } from 'date-fns-tz'
import add from 'date-fns/add'

const desc = ['Bad', 'Average', 'Good']

const AppointmentDetailsModal = ({ appointmentId, show, onClose }) => {
  const [form] = Form.useForm()
  const [showCreateFeedbackModal, setShowCreateFeedbackModal] = useState(false)
  const { data: appointmentDetails, isLoading, refetch: refetchAppointment } = useAppointmentsInfoById(appointmentId)
  const { data: answers } = useAnswersByAppointmentId(appointmentId, { detailed: true })
  const { data: userData } = useUserInfo()

  const onCreateFeedback = (values) => {
    const data = {
      ...values
    }
    console.log('onCreateFeedback values of form: ', data)
    axios
      .put(process.env.REACT_APP_API_URL + '/feedback/', data, {
        headers: {
          'Content-Type': 'application/json',
          id: appointmentDetails?.feedback?.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Update Feedback: response: ', response)
        return response.data
      })
      .finally(() => {
        setShowCreateFeedbackModal(false)
        refetchAppointment()
      })
      .catch((err) => console.error('Update Feedback error: ', err.response?.data?.message, err))
  }
  if (isLoading) {
    return (
      <ContentScreenMiddleFixed>
        <Spin spinning={true} delay={1000}>
          <Alert message="Loading..." description="Please wait while we retrieve meetings" type="info" />
        </Spin>
      </ContentScreenMiddleFixed>
    )
  }

  const ColumnData = ({ text, data }) =>
    !!data && (
      <>
        <StyledLabel>{text}</StyledLabel>
        <StyledInfoField>{data}</StyledInfoField>
      </>
    )
  if (!appointmentDetails) return <></>
  return (
    <Modal
      visible={show && appointmentDetails}
      onCancel={onClose}
      bodyStyle={
        isMobile
          ? { minHeight: '50vh', padding: 0, borderRadius: '20px' }
          : { minHeight: '50vh', padding: 0, margin: '2rem 0', borderRadius: '20px' }
      }
      width={isMobile ? '100vw' : '90%'}
      centered
      destroyOnClose
      className="modalStyle"
      footer={null}
    >
      <div className="appointment-details-grid-container">
        <div className="appointment-details-header">
          <h2>Meeting with {appointmentDetails?.lead?.companyName}</h2>
          <p>{`(ID #${appointmentDetails?.id}, Created ${format(new Date(appointmentDetails?.createdAt), 'MMM do yyyy HH:mm', {
            timeZone: 'Europe/Berlin'
          })})`}</p>
        </div>
        <div className="appointments-details-lead-header">
          <h2>Lead</h2>
        </div>
        <div className="appointments-details-question-container">
          <h2>Questions</h2>
          {answers?.length ? (
            answers.map((item) => <ColumnData key={item.id} text={item.question?.question} data={item.answer} />)
          ) : (
            <Empty description="No questions found." />
          )}
        </div>
        <div className="appointments-details-leadname">
          <StyledLabel>Company name</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyName}</StyledInfoField>
        </div>
        <div className="appointments-details-org">
          <StyledLabel>Org no</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyOrgNumber}</StyledInfoField>
        </div>
        <div className="appointments-details-adr">
          <StyledLabel>Address</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyAddress}</StyledInfoField>
        </div>
        <div className="appointments-details-contact">
          <StyledLabel>Contact person</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyContactPerson}</StyledInfoField>
        </div>
        <div className="appointments-details-phone">
          <StyledLabel>Phone</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyPhoneNumber}</StyledInfoField>
        </div>
        <div className="appointments-details-role">
          <StyledLabel>Contact position</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyContactPosition}</StyledInfoField>
        </div>
        <div className="appointments-details-email">
          <StyledLabel>Email</StyledLabel>
          <StyledInfoField>{appointmentDetails?.lead?.companyEmail}</StyledInfoField>
        </div>
        <div className="appointments-details-feedback-container">
          <h2>Feedback</h2>
          {appointmentDetails?.feedback?.rating ? (
            <>
              <StyledLabel>Meeting status: </StyledLabel>
              <StyledInfoField>{appointmentDetails?.feedback?.meetingStatus}</StyledInfoField>

              <ColumnData text="Feedback note" data={appointmentDetails?.feedback?.note} />
              <StyledLabel>Rating:</StyledLabel>
              <StyledInfoField>
                <Rate count={3} tooltips={desc} value={appointmentDetails?.feedback?.rating} disabled />
                {appointmentDetails?.feedback?.rating ? (
                  <span className="ant-rate-text">{desc[appointmentDetails?.feedback?.rating - 1]}</span>
                ) : (
                  ''
                )}
              </StyledInfoField>
            </>
          ) : (
            <Empty description="No feedback found." />
          )}
        </div>
        {userData?.role === 'CLIENT' && (
          <div className="appointments-details-feedbackbutton">
            <StyledButton
              type="primary"
              shape="round"
              size="standard"
              onClick={() => {
                form.setFieldsValue({
                  meetingStatus: appointmentDetails?.feedback?.meetingStatus,
                  note: appointmentDetails?.feedback?.note,
                  rating: appointmentDetails?.feedback?.rating
                })
                setShowCreateFeedbackModal(true)
              }}
              icon={appointmentDetails?.feedback?.rating ? <EditOutlined /> : <PlusOutlined />}
            >
              {appointmentDetails?.feedback?.rating ? 'Edit' : 'Give'} feedback
            </StyledButton>
          </div>
        )}
        <div className="appointments-details-detailstitle">
          <h2>Meeting details</h2>
        </div>
        <div className="appointments-details-date">
          <StyledLabel>Date</StyledLabel>
          <StyledInfoField>
            {format(new Date(appointmentDetails?.meetingDate), 'MMM do yyyy', { timeZone: 'Europe/Berlin' })}
          </StyledInfoField>
        </div>
        <div className="appointments-details-time">
          <StyledLabel>Time</StyledLabel>
          <StyledInfoField>
            {format(new Date(appointmentDetails?.meetingDate), 'HH:mm', { timeZone: 'Europe/Berlin' })} -{' '}
            {format(add(new Date(appointmentDetails?.meetingDate), { hours: 1 }), 'HH:mm', { timeZone: 'Europe/Berlin' })}
          </StyledInfoField>
        </div>
        <div className="appointments-details-digital">
          <StyledLabel>Digital/Physical</StyledLabel>
          <StyledInfoField>{appointmentDetails?.meetingIsDigital ? 'Digital' : 'Physical'}</StyledInfoField>
        </div>
        <div className="appointments-details-note">
          <StyledLabel>Meeting note</StyledLabel>
          <StyledInfoField>{appointmentDetails?.meetingNote}</StyledInfoField>
        </div>
        <div className="modal-button-cancel">
          <StyledButton
            // type="primary"
            shape="round"
            size="large"
            onClick={onClose}
          >
            Close
          </StyledButton>
        </div>
      </div>

      <Modal
        // onOk={handleCreateAppointment}
        // confirmLoading={confirmLoading}
        // onCancel={handleCancel}
        // bodyStyle={{ minHeight: '80vh' }}
        // width="90vw"
        visible={showCreateFeedbackModal}
        okText="Save"
        cancelText="Cancel"
        className="modalStyle"
        bodyStyle={{ padding: 0, borderRadius: '20px' }}
        onCancel={() => setShowCreateFeedbackModal(false)}
        width="50vw"
        footer={null}
        centered
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            meetingStatus: appointmentDetails?.feedback?.meetingStatus,
            rating: appointmentDetails?.feedback?.rating,
            note: appointmentDetails?.feedback?.note
          }}
        >
          <div className="create-feedback-container">
            <div className="create-feedback-header">
              <h2>Give feedback</h2>
            </div>
            <div className="create-feedback-status">
              <Form.Item
                name="meetingStatus"
                label={<StyledLabel>Meeting status</StyledLabel>}
                rules={[
                  {
                    required: true,
                    message: 'Please select meeting status'
                  }
                ]}
              >
                <Select showSearch placeholder="Select status">
                  <Select.Option value="Won">Won</Select.Option>
                  <Select.Option value="Lost">Lost</Select.Option>
                  <Select.Option value="Postponed">Postponed</Select.Option>
                  <Select.Option value="Sent offer">Sent offer</Select.Option>
                  <Select.Option value="Negotiation">Negotiation</Select.Option>
                  <Select.Option value="Closing">Closing</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="create-feedback-note">
              <Form.Item
                name="note"
                label={<StyledLabel>Feedback note</StyledLabel>}
                // label="Feedback note"
                rules={[
                  {
                    required: true,
                    message: 'Please input the title of collection!'
                  }
                ]}
              >
                <StyledTextArea rows={4} />
              </Form.Item>
            </div>
            <div className="create-feedback-rating">
              <Form.Item
                label={<StyledLabel>How would you rate the meeting?</StyledLabel>}
                name="rating"
                className="collection-create-form_last-form-item"
              >
                <Radio.Group>
                  <Radio value="1">Bad</Radio>
                  <Radio value="2">Average</Radio>
                  <Radio value="3">Good</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <ModalButtons
              onCancel={() => setShowCreateFeedbackModal(false)}
              okText={appointmentDetails?.feedback?.rating ? 'Update' : 'Create'}
              onOk={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields()
                    onCreateFeedback(values)
                    refetchAppointment()
                  })
                  .catch((info) => {
                    console.log('Validate Feedback Failed:', info)
                  })
              }}
            />
          </div>
        </Form>
      </Modal>
    </Modal>
  )
}
export default AppointmentDetailsModal
