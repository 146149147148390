import React from "react"
import AppointmentsTableClient from "../../components/AppointmentsTable/AppointmentsTableClient"

const ClientInfo = ({ user }) => {
  if (!user.username) return <p>Error fetching client info</p>

  return (
    <div>
      <AppointmentsTableClient user={user} />
    </div>
  )
}
export default ClientInfo
