import React, { useEffect, useState } from 'react'
import { Button, Space, Tooltip, Table, Popconfirm } from 'antd'
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { deleteUser, useAppointmentsInfoByVendorId, useGetAllAgents } from '../../fetchHooks/useFetchHooks'
import { LinkButton } from '../../pages/HomePage/styles'
import { StyledButton, TableContainer } from '../../styles'
import CreateAgentForm from '../CreateAgent/CreateAgentForm'

const UsersTableMaster = ({ user }) => {
  const [showAgentDetailsModal, setShowAgentDetailsModal] = useState(false)
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false)
  const { data: agents, isLoading, isError, refetch } = useGetAllAgents()

  useEffect(() => {
    agents && console.log('AgentsTableAdmin agents: ', agents)
  }, [agents])

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const columns = !agents
    ? []
    : [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          key: 'id',
          render: (id, item) => <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{id}</LinkButton>
        },
        {
          title: 'Username',
          dataIndex: 'username',
          sorter: (a, b) => a.username.localeCompare(b.username),
          key: 'username',
          render: (username, item) => <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{`${username} `}</LinkButton>
        },
        {
          title: 'Name',
          dataIndex: 'firstname',
          sorter: (a, b) => a.lastname.localeCompare(b.lastname),
          key: 'companyName',
          render: (firstname, { lastname, id }) => (
            <LinkButton onClick={() => setShowAgentDetailsModal(id)}>
              <strong>{lastname}</strong>, {firstname}
            </LinkButton>
          )
        },
        {
          title: 'Role',
          dataIndex: 'role',
          sorter: (a, b) => a.role.localeCompare(b.role),
          key: 'role',
          render: (role, customer) => <div>{role}</div>
        },
        {
          title: 'Vendor',
          dataIndex: 'vendor',
          sorter: (a, b) => a.vendor?.companyName.localeCompare(b.vendor?.companyName),
          key: 'vendor',
          render: (vendor, item) => (
            <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{`${vendor?.companyName ?? 'N/A'} `}</LinkButton>
          )
        },
        {
          title: 'Customer',
          dataIndex: 'customer',
          sorter: (a, b) => a.customer?.companyName?.localeCompare(b.customer?.companyName),
          key: 'customer',
          render: (customer, item) => (
            <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{`${customer?.companyName ?? 'N/A'} `}</LinkButton>
          )
        },

        {
          title: 'Action',
          key: 'action',
          render: (text, item) => (
            <Space size="middle">
              {/* <Button
                type="primary"
                shape="round"
                size="default"
                icon={<EyeOutlined />}
                onClick={() => setShowAgentDetailsModal(item.id)}
                ghost
                className="edit-button"
              /> */}
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => deleteUser(item.id, refetch)}
                onCancel={() => console.log('Canceled')}
                okText="Yes"
                cancelText="No"
              >
                <StyledButton type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
              </Popconfirm>
            </Space>
          )
        }
      ]
  if (isLoading) return <h1>Loading...</h1>

  return (
    <TableContainer>
      <h2>Users:</h2>
      <Table dataSource={agents} columns={columns} rowKey="id" />

      <Tooltip title="Add new agent">
        <StyledButton type="primary" shape="round" size="default" onClick={() => setShowCreateAgentModal(true)} icon={<PlusOutlined />}>
          Add agent
        </StyledButton>
      </Tooltip>

      <CreateAgentForm
        visible={showCreateAgentModal}
        onCreate={() => {
          setShowCreateAgentModal(false)
          refetch()
        }}
        onCancel={() => setShowCreateAgentModal(false)}
        vendorId={user.vendorId}
      />
    </TableContainer>
  )
}

export default UsersTableMaster
