import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Checkbox, Form } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { PlusCircleOutlined } from '@ant-design/icons'
import { StyledButton, StyledCancelButton, StyledLabel, StyledTextInput } from '../../styles'
import { useUserInfo } from '../../fetchHooks/useFetchHooks'

export const ModalButtons = ({ onOk, onCancel, okText = 'Add', cancelText = 'Cancel' }) => (
  <>
    <div className="modal-button-canceladd">
      {onCancel && (
        <StyledCancelButton shape="round" size="large" onClick={onCancel}>
          {cancelText}
        </StyledCancelButton>
      )}
      {onOk && (
        <StyledButton shape="round" size="large" onClick={onOk} icon={okText === 'Add' && <PlusCircleOutlined />}>
          {okText}
        </StyledButton>
      )}
    </div>
  </>
)

const CreateAgentForm = ({ visible, onCreate, onCancel, vendorId }) => {
  console.log('CreateAgentForm: ')
  const [form] = Form.useForm()
  const [isAdmin, setIsAdmin] = useState(false)
  const { data: user } = useUserInfo()

  useEffect(() => {
    console.log('CreateAgend user: ', user)
  }, [user])

  const handleCreateAgent = (values) => {
    const data = { ...values, vendorId }
    console.log('handleCreateAgent data', data)
    axios
      .post(process.env.REACT_APP_API_URL + '/users/addagent', data, {
        headers: {
          'Content-Type': 'application/json',
          id: vendorId
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create agent: response: ', response)
        return response.data
      })
      .finally(() => onCreate())
      .catch((err) => console.error('Create agent error: ', err.response?.data?.message, err.response))
  }
  return (
    <Modal
      visible={visible}
      title="Create a new agent"
      okText="Create"
      bodyStyle={{ padding: 0, borderRadius: '20px' }}
      cancelText="Cancel"
      className="modalStyle"
      onCancel={onCancel}
      footer={null}
      onOk={() => console.log('Validate Failed:')}
      // onOk={() => {
      //   form
      //     .validateFields()
      //     .then((values) => {
      //       handleCreateAgent(values)
      //       onCreate(values)
      //       form.resetFields()
      //     })
      //     .catch((info) => {
      //       console.log('Validate Failed:', info)
      //     })
      // }}
    >
      <Form form={form} layout="vertical" name="form_in_modal_create_agent" initialValues={{}}>
        <div className="create-user-container">
          <div className="create-user-header">
            <h2>Create new agent </h2>
          </div>
          <div className="create-user-firstname">
            <Form.Item label={<StyledLabel>First name</StyledLabel>} name="firstname">
              <StyledTextInput type="textarea" />
            </Form.Item>
          </div>
          <div className="create-user-lastname">
            <Form.Item label={<StyledLabel>Last name</StyledLabel>} name="lastname">
              <StyledTextInput type="textarea" />
            </Form.Item>
          </div>
          <div className="create-user-email">
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input a valid email address'
                }
              ]}
              label={<StyledLabel>Email address</StyledLabel>}
              name="email"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-user-isadmin">
            <div>
              <StyledLabel>Admin rights</StyledLabel>
            </div>
            <div>
              <Form.Item name="isAdmin">
                <Checkbox style={{ marginLeft: 16 }} checked={isAdmin} onChange={(e) => setIsAdmin((prev) => !prev)} />
              </Form.Item>
            </div>
          </div>

          <ModalButtons
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  const vals = {
                    ...values,
                    isAdmin
                  }
                  handleCreateAgent(vals)
                  onCreate(vals)
                  console.log(vals)
                  form.resetFields()
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
            onCancel={onCancel}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default CreateAgentForm
