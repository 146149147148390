import React, { useEffect, useState } from 'react'
import { filterDataByDatapoint, washUrl } from '../../utils/utils'
import { Space, Tooltip, Popconfirm, Table, Checkbox, Spin, Alert } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import CreateAppointment from '../CreateAppointment/CreateAppointment'
import { deleteAppointment, useAppointmentsInfoByUser } from '../../fetchHooks/useFetchHooks'
import AppointmentDetailsModal from '../AppointmentDetailsModal/AppointmentDetailsModal'
import { LinkButton } from '../../pages/HomePage/styles'
import moment from 'moment-timezone'
import { ExportJsonCsv } from 'react-export-json-csv'
import { StyledButton, StyledLabelGreen, StyledLabelWhite, StyledLabelWhiteBold, TableContainer } from '../../styles'
import EditAppointment from '../CreateAppointment/EditAppointment'
import FilterSelectComponent from './FilterSelectComponent'

const AppointmentsTableAgent = ({ user }) => {
  const [customerSearchString, setCustomerSearchString] = useState('')
  const [showAppointmentDetailsModal, setShowAppointmentDetailsModal] = useState(false)
  const [selectedAppointment, setSelectedAppointment] = useState(false)
  const [showCreateAppointmentModal, setShowCreateAppointmentModal] = useState(false)
  const { data: appointments, isLoading, refetch } = useAppointmentsInfoByUser(user)
  const [tableKey, setTableKey] = useState(0)
  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1)
  }
  useEffect(() => {
    console.log('user: ' + user)
  }, [user])

  const handleCancel = () => {
    setShowCreateAppointmentModal(false)
  }

  const handleCreateAppointment = (values) => {
    setShowCreateAppointmentModal(false)
    refetch()
    console.log('Refetcing appintments')
  }
  const handleShowAppointment = (values) => {
    setShowAppointmentDetailsModal(false)
    refetch()
    console.log('handleShowAppointment Refetcing appintments')
  }
  const handleUpdateAppointment = (values) => {
    setSelectedAppointment(false)
    refetch()
    console.log('Refetcing appintments')
  }

  if (isLoading || !appointments) {
    console.log(isLoading, appointments)
    return (
      <Spin spinning={isLoading} delay={500}>
        <Alert message="Loading..." description="Please wait while we retrieve meetings" type="info" />
      </Spin>
    )
  }
  const items = appointments?.map((item) => {
    return {
      ...item,
      customerCompanyName: item.customer?.companyName,
      leadCompanyName: item.lead?.companyName,
      digital: item.meetingIsDigital ? 'Yes' : 'No',
      meetingApproved: item.approved ? 'Yes' : 'No',
      meetingDateFormatted: moment(item.meetingDate).tz('Europe/Berlin').format(),
      status: item.feedback?.meetingStatus
    }
  })

  const columns = !items
    ? []
    : [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          key: 'id',
          render: (id, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{id}</LinkButton>,
          responsive: ['sm']
        },
        {
          title: 'Date',
          width: '10%',
          dataIndex: 'meetingDate',
          sorter: (a, b) => a.meetingDate.localeCompare(b.meetingDate),
          key: 'meetingDate',
          render: (meetingDate, item) => (
            <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>
              {moment(meetingDate).tz('Europe/Berlin').format('MMM Do YYYY')}
            </LinkButton>
          )
        },
        {
          title: 'Note',
          dataIndex: 'meetingNote',
          width: '30%',
          sorter: (a, b) => a.meetingNote.localeCompare(b.meetingNote),
          key: 'meetingNote',
          render: (meetingNote, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{meetingNote}</LinkButton>,
          responsive: ['sm']
        },
        {
          title: 'Customer',
          key: 'customer',

          // filterMode: 'menu',
          // filters: filterDataByDatapoint(appointments, 'customer.companyName'),
          // filterSearch: true,
          // onFilter: (value, record) => record.customer.companyName.includes('BliVakker AS'),
          // onFilter: (value, record) => record.customer.companyName.includes(value),
          dataIndex: 'customer',
          sorter: (a, b) => a.customer?.companyName.localeCompare(b.customer?.companyName),
          render: (customer, item) => (
            <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{customer?.companyName}</LinkButton>
          )
        },
        {
          title: 'Lead',
          key: 'lead',
          dataIndex: 'lead',
          sorter: (a, b) => a.lead?.companyName.localeCompare(b.lead?.companyName),
          render: (lead, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{lead?.companyName}</LinkButton>
        },
        {
          title: 'Appr.',
          key: 'meetingApproved',
          filters: filterDataByDatapoint(items, 'meetingApproved'),
          filterMode: 'menu',
          filterSearch: true,
          onFilter: (value, record) => record.meetingApproved?.toLowerCase?.().includes(value.toLowerCase()),
          dataIndex: 'meetingApproved',
          sorter: (a, b) => a.meetingApproved.localeCompare(b.meetingApproved),
          render: (approved, item) => <Checkbox checked={item.approved} disabled />
        },
        {
          title: 'Digital?',
          key: 'digital',
          filters: filterDataByDatapoint(items, 'digital'),
          filterMode: 'menu',
          filterSearch: true,
          onFilter: (value, record) => record.digital?.toLowerCase?.().includes(value.toLowerCase()),
          dataIndex: 'digital',
          sorter: (a, b) => a.digital.localeCompare(b.digital),
          render: (digital, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{digital}</LinkButton>
        },
        {
          title: 'Status',
          key: 'feedback',
          dataIndex: 'feedback',
          filters: filterDataByDatapoint(appointments, 'feedback.meetingStatus'),
          filterMode: 'menu',
          filterSearch: true,
          onFilter: (value, record) => record.feedback?.meetingStatus?.toLowerCase?.().includes(value.toLowerCase()),
          sorter: (a, b) => a.feedback?.meetingStatus?.localeCompare(b.feedback?.meetingStatus),
          render: (feedback, item) => (
            <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{feedback?.meetingStatus}</LinkButton>
          ),
          responsive: ['sm']
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, item) => (
            <Space size="middle">
              <Tooltip title="Open appointment details">
                <StyledButton
                  shape="round"
                  size="default"
                  icon={<EyeOutlined />}
                  onClick={() => setShowAppointmentDetailsModal(item.id)}
                  className="edit-button"
                />
              </Tooltip>
              <Tooltip
                title={item.approved ? 'Approved appointments can not be edited. Ask admin to unapprove if necessary.' : 'Edit meeting'}
              >
                <div>
                  <StyledButton
                    type="primary"
                    shape="round"
                    size="default"
                    icon={<EditOutlined />}
                    onClick={() => setSelectedAppointment(item)}
                    disabled={item.approved}
                    className="edit-button"
                    style={{ pointerEvents: item.approved && 'none' }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Delete appointment">
                <Popconfirm
                  title="Are you sure to delete this item?"
                  onConfirm={() => deleteAppointment(item.id, refetch)}
                  onCancel={() => console.log('Canceled')}
                  okText="Yes"
                  cancelText="No"
                >
                  <StyledButton type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
                </Popconfirm>
              </Tooltip>
            </Space>
          ),
          responsive: ['sm']
        }
      ]

  const headers = [
    { key: 'id', name: 'ID' },
    { key: 'meetingDateFormatted', name: 'Meeting Date' },
    { key: 'customerCompanyName', name: 'Customer Name' },
    { key: 'leadCompanyName', name: 'Lead Name' },
    { key: 'meetingNote', name: 'Note' },
    { key: 'digital', name: 'Meeting is Digital?' },
    { key: 'meetingApproved', name: 'Approved?' },
    { key: 'status', name: 'Status' }
  ]

  return (
    <TableContainer>
      <StyledLabelWhite>
        Welcome, {user.firstname}! You have <StyledLabelGreen>{appointments.length}</StyledLabelGreen> appointments waiting for you.
      </StyledLabelWhite>
      <hr />
      <div className="customer-filter-select-container">
        <StyledLabelWhiteBold style={{ marginRight: '2rem' }}>Appointments: </StyledLabelWhiteBold>
        <FilterSelectComponent appointments={appointments} onChange={setCustomerSearchString} />
      </div>
      <Table
        dataSource={
          customerSearchString
            ? items?.filter((item) => item.customer?.companyName?.toLowerCase?.().includes(customerSearchString?.toLowerCase?.()))
            : items
        }
        columns={columns}
        rowKey="id"
        key={tableKey}
      />
      <Tooltip title="Add new appointment">
        <StyledButton
          // type="primary"
          shape="round"
          size="default"
          onClick={() => setShowCreateAppointmentModal(true)}
          icon={<PlusOutlined />}
        >
          New appointment
        </StyledButton>
      </Tooltip>
      <Tooltip title="Export data">
        <ExportJsonCsv
          fileTitle={`Exported_appointments__${washUrl(user.vendor?.companyName || user.customer?.companyName)}`}
          className="csv-export-button"
          headers={headers}
          items={items}
        >
          Export
        </ExportJsonCsv>
      </Tooltip>
      <Tooltip title="Clear filter and show all data in above table">
        <StyledButton shape="round" size="default" className="csv-export-button" onClick={() => resetFilters()}>
          Clear Filters
        </StyledButton>
      </Tooltip>
      <CreateAppointment
        showCreateAppointmentModal={showCreateAppointmentModal}
        onClose={handleCancel}
        onOk={handleCreateAppointment}
        user={user}
      />

      {selectedAppointment && (
        <EditAppointment
          showEditAppointmentModal={selectedAppointment}
          onClose={() => setSelectedAppointment(false)}
          onOk={handleUpdateAppointment}
          user={user}
          appointment={selectedAppointment}
        />
      )}
      <AppointmentDetailsModal
        show={!!showAppointmentDetailsModal}
        appointmentId={showAppointmentDetailsModal}
        onClose={handleShowAppointment}
      />

      <CreateAppointment
        showCreateAppointmentModal={showCreateAppointmentModal}
        onClose={() => setShowCreateAppointmentModal(false)}
        onOk={handleCreateAppointment}
        user={user}
      />

      {selectedAppointment && (
        <EditAppointment
          showEditAppointmentModal={selectedAppointment}
          onClose={() => setSelectedAppointment(false)}
          onOk={handleUpdateAppointment}
          user={user}
          appointment={selectedAppointment}
        />
      )}
    </TableContainer>
  )
}

export default AppointmentsTableAgent
