import styled from '@emotion/styled'
import { Button, Input, InputNumber, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

export const AppWrapper = styled.div`
  margin: 0rem;
  width: 100%;

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
  }
`

export const AppContainer = styled.div`
  margin-left: auto;
  margin-right: auto;

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
  }
`

export const Grid = styled.div`
  margin: 0rem;

  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    grid-auto-rows: minmax(100px, auto);
  }
`

export const CenteredContent = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
`

export const ContentScreenMiddleFixed = styled.div`
  position: fixed;
  width: 500px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
`

export const TableContainer = styled.div`
  margin-bottom: 10rem; /* Negative half of height. */
`

export const StyledButton = styled(Button)`
  /* font-family: 'MontHeavy'; */
  font-family: FuturaMedium;
  font-size: 16px;
  background: rgb(180, 247, 200) !important;
  border: none;
  color: rgb(53, 58, 69) !important;
  /* font-weight: 500; */
  font-size: 16px;
  &:hover {
    border: none;
    background-color: rgba(180, 247, 200, 0.9);
  }
`

export const TableAddButton = styled(StyledButton)`
  margin-top: 1rem;
`

export const StyledCancelButton = styled(StyledButton)`
  /* font-family: 'MontHeavy'; */
  color: white;
  background: rgb(53, 58, 69);
  margin-right: 1rem;
  /* font-weight: 500; */
  &:hover {
    border: none;
    background-color: rgba(53, 58, 69, 0.9);
  }
`

export const StyledInput = styled.input`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  padding: 8px;
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  font-size: 16px;
`
export const StyledTextArea = styled(TextArea)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  padding: 8px;
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  font-size: 16px;
`
export const StyledTextInput = styled(Input)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  /* padding: 8px; */
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  /* font-size: 16px; */
`
export const StyledTextInputNumber = styled(InputNumber)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  /* padding: 8px; */
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  width: 100%;
  /* width: 100%; */
  /* font-size: 16px; */
`
export const StyledSelect = styled(Select)`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;

  background: white;
  padding: 8px;
  font-weight: 10;
  /* border: 1px solid rgb(53, 58, 69); */
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
  /* font-size: 16px; */
  cursor: pointer;
  .ant-select-selector.ant-select-selector.ant-select-selector {
    /* border: 0px !important; */
    &:hover {
      cursor: pointer;
    }
  }
`

export const StyledInfoField = styled.p`
  /* font-family: 'MontHeavy'; */
  font-family: Futura;
  font-size: 16px;

  background: white;
  padding: 8px;
  font-weight: 10;
  border: 1px solid rgb(53, 58, 69);
  border-radius: 15px;
  color: rgb(53, 58, 69);
  /* width: 100%; */
`

export const StyledLabel = styled.p`
  font-size: 16px;
  @media only screen and (min-width: 1024px) {
    font-size: 24px;
    font-family: FuturaMedium;
    color: rgb(53, 58, 69);
  }
`
export const StyledLabelSpan = styled.span`
  font-size: 16px;
  @media only screen and (min-width: 1024px) {
    font-size: 20px;
    font-family: FuturaMedium;
    color: rgb(53, 58, 69);
  }
`

export const StyledLabelGreen = styled.span`
  font-size: 24px;
  color: rgb(180, 247, 200);
  text-decoration: underline;
  font-family: FuturaMedium;
`
export const StyledLabelWhite = styled.p`
  font-size: 16px;

  // Desktop:
  @media only screen and (min-width: 1024px) {
    font-size: 24px;
    font-family: FuturaMedium;
    color: white;
  }
`
export const StyledLabelWhiteBold = styled(StyledLabelWhite)`
  font-family: FuturaBold;
  color: white;
`
