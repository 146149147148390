import axios from 'axios'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'

const Signin = () => {
  // const [cookies, setCookie] = useCookies(['test-session'])

  // useEffect(() => {
  //   console.log('cookies: ', cookies)
  // }, [cookies])

  const {
    data: userData,
    isLoading,
    isError,
    isSuccess
  } = useQuery('userData', () => {
    return axios
      .post(
        process.env.REACT_APP_API_URL + '/auth/signin',
        {
          username: 'intercom',
          password: 'heihei'
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        }
      )
      .then((response) => {
        console.log('response: ', response)
        return response.data
      })
  })

  useEffect(() => {
    console.log('userData: ', userData)
  }, [userData])

  if (isLoading) {
    return <h1>Loading...</h1>
  }

  if (isError) {
    return <h1>Something went wrong</h1>
  }

  return (
    <ul>
      {isSuccess && (
        <>
          <p>Signed in</p>
          <p>{userData.id}</p>
          <p>{userData.username}</p>
        </>
      )}
    </ul>
  )
}

export default Signin
