import React, { useEffect, useState } from 'react'
import { filterDataByDatapoint, washUrl } from '../../utils/utils'
import { Tooltip, Table, Spin, Alert } from 'antd'
import { useAppointmentsInfoByClient } from '../../fetchHooks/useFetchHooks'
import AppointmentDetailsModal from '../AppointmentDetailsModal/AppointmentDetailsModal'
import { LinkButton } from '../../pages/HomePage/styles'
import moment from 'moment-timezone'
import { ExportJsonCsv } from 'react-export-json-csv'
import { StyledButton, StyledLabelGreen, StyledLabelWhite, TableContainer } from '../../styles'

const AppointmentsTableClient = ({ user }) => {
  const [showAppointmentDetailsModal, setShowAppointmentDetailsModal] = useState(false)
  const { data: appointments, isLoading, refetch } = useAppointmentsInfoByClient(user)
  const [tableKey, setTableKey] = useState(0)
  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1)
  }
  useEffect(() => {
    console.log('AAAA: ', appointments)
  }, [appointments])

  const handleShowAppointment = (values) => {
    setShowAppointmentDetailsModal(false)
    refetch()
    console.log('handleShowAppointment Refetcing appintments')
  }
  if (isLoading) {
    return (
      <Spin spinning={isLoading} delay={500}>
        <Alert message="Loading..." description="Please wait while we retrieve meetings" type="info" />
      </Spin>
    )
  }
  const headers = [
    { key: 'id', name: 'ID' },
    { key: 'meetingDateFormatted', name: 'Meeting Date' },
    { key: 'leadCompanyName', name: 'Company Name' },
    { key: 'meetingNote', name: 'Note' },
    { key: 'digital', name: 'Meeting is Digital?' }
  ]

  const items = appointments?.map((item) => {
    return {
      ...item,
      customerCompanyName: item.customer?.companyName,
      leadCompanyName: item.lead?.companyName,
      digital: item.meetingIsDigital ? 'Yes' : 'No',
      meetingApproved: item.approved ? 'Yes' : 'No',
      meetingDateFormatted: moment(item.meetingDate).tz('Europe/Berlin').format(),
      status: item.feedback?.meetingStatus
    }
  })
  const columns = !items
    ? []
    : [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: (a, b) => a.id - b.id,
          key: 'id',
          render: (id, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{id}</LinkButton>
        },
        {
          title: 'Date',
          dataIndex: 'meetingDate',
          sorter: (a, b) => a.meetingDate.localeCompare(b.meetingDate),
          key: 'meetingDate',
          render: (meetingDate, item) => (
            <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>
              {moment(meetingDate).tz('Europe/Berlin').format('MMM Do YYYY')}
            </LinkButton>
          )
        },
        {
          title: 'Note',
          dataIndex: 'meetingNote',
          sorter: (a, b) => a.meetingNote.localeCompare(b.meetingNote),
          key: 'meetingNote',
          render: (meetingNote, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{meetingNote}</LinkButton>
        },
        {
          title: 'Lead',
          key: 'lead',
          dataIndex: 'lead',
          sorter: (a, b) => a.lead?.companyName.localeCompare(b.lead?.companyName),
          render: (lead, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{lead?.companyName}</LinkButton>
        },
        {
          title: 'Digital?',
          key: 'digital',
          filters: filterDataByDatapoint(items, 'digital'),
          filterMode: 'menu',
          filterSearch: true,
          dataIndex: 'digital',
          onFilter: (value, record) => record.digital?.toLowerCase?.().includes(value.toLowerCase()),
          sorter: (a, b) => a.digital.localeCompare(b.digital),
          render: (digital, item) => <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{digital}</LinkButton>
        },

        {
          title: 'Status',
          key: 'feedback',
          dataIndex: 'feedback',
          filters: filterDataByDatapoint(items, 'feedback.meetingStatus'),
          filterMode: 'menu',
          filterSearch: true,
          onFilter: (value, record) => record.feedback?.meetingStatus?.toLowerCase?.().includes(value.toLowerCase()),
          sorter: (a, b) => a.feedback?.meetingStatus?.localeCompare(b.feedback?.meetingStatus),
          render: (feedback, item) => (
            <LinkButton onClick={() => setShowAppointmentDetailsModal(item.id)}>{feedback?.meetingStatus}</LinkButton>
          )
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, item) => (
            <StyledButton
              type="primary"
              shape="round"
              size="default"
              onClick={() => setShowAppointmentDetailsModal(item.id)}
              ghost
              className="edit-button"
            >
              View
            </StyledButton>
          )
        }
      ]

  return (
    <TableContainer>
      <StyledLabelWhite>
        Welcome, {user.firstname}! You have <StyledLabelGreen>{appointments.length}</StyledLabelGreen> appointments waiting for you.
      </StyledLabelWhite>
      <hr />
      <div className="customer-filter-select-container" />
      <Table dataSource={items} columns={columns} rowKey="id" key={tableKey} />
      {appointments?.length && (
        <>
          <Tooltip title="Export data">
            <ExportJsonCsv
              fileTitle={`Exported_appointments__${washUrl(user.vendor?.companyName || user.customer?.companyName)}`}
              className="csv-export-button"
              headers={headers}
              items={items}
            >
              Export
            </ExportJsonCsv>
          </Tooltip>
          <Tooltip title="Clear filter and show all data in above table">
            <StyledButton shape="round" size="default" className="csv-export-button" onClick={() => resetFilters()}>
              Clear Filters
            </StyledButton>
          </Tooltip>
          <AppointmentDetailsModal
            show={!!showAppointmentDetailsModal}
            appointmentId={showAppointmentDetailsModal}
            onClose={handleShowAppointment}
          />
        </>
      )}
    </TableContainer>
  )
}

export default AppointmentsTableClient
