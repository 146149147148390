import { message } from 'antd'
import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'
// eslint-disable-next-line no-undef
export const envApiUrl = process.env.REACT_APP_API_URL

export const useVendorInfoByUser = (user, options = {}) => {
  return useQuery(
    ['vendorInfo', user],

    () =>
      axios
        .get(`${envApiUrl}/vendors`, {
          headers: {
            'Content-Type': 'application/json'
            // id: user?.vendorId,
            // username: user?.username
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: true,
      ...options
    }
  )
}

export const useCustomers = (options = {}) => {
  return useQuery(
    ['useCustomers'],

    () =>
      axios
        .get(`${envApiUrl}/customers/byvendorid`, {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: true,
      ...options
    }
  )
}

export const useGetAllVendors = (options = {}) => {
  return useQuery(
    ['GetAllVendors'],

    () =>
      axios
        .get(`${envApiUrl}/vendors/getallvendors`, {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: true,
      ...options
    }
  )
}
export const useGetAllCustomers = (options = {}) => {
  return useQuery(
    ['GetAllCustomers'],

    () =>
      axios
        .get(`${envApiUrl}/customers/getallcustomers`, {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: true,
      ...options
    }
  )
}
export const useAgentsByVendorId = (user, options = {}) => {
  return useQuery(
    ['AgentsByVendorId', user],

    () =>
      user &&
      axios
        .get(`${envApiUrl}/users/byvendorid`, {
          headers: {
            'Content-Type': 'application/json',
            id: user?.vendorId
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!user,
      ...options
    }
  )
}

export const useLeadsInfoByUser = (user, options = {}) => {
  return useQuery(
    ['leadsinfo', user],

    () =>
      user &&
      axios
        .get(`${envApiUrl}/leads`, {
          headers: {
            'Content-Type': 'application/json',
            id: user?.vendorId,
            username: user?.username
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!user,
      ...options
    }
  )
}

export const useBrregInfo = (orgNumber, options = {}) => {
  return useQuery(
    ['brreginfo', orgNumber],
    () =>
      orgNumber?.length > 8 &&
      axios
        .get(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => response.data),
    {
      enabled: !!orgNumber,
      ...options
    }
  )
}

const checkErrorResponse = (error) => {
  if (error.response) {
    console.log('AAA: ', error.response.data) // Message
    console.log(error.response.status) // Error code number
    console.log(error.response.headers)
  }
  if (error.response?.status === 401) {
    console.log('401 UNAUTHORIZED: Signing out')
    window.location.href = '/signout'
  }
  if (error.response?.status === 403) {
    console.log('403 FORBIDDEN: Signing out')
    window.location.href = '/signout'
  }
}

export const useAppointmentsInfoByUser = (options = {}) => {
  return useQuery(
    ['appointmentsByUser'],
    () =>
      axios
        .get(envApiUrl + '/appointments/byuserid', {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .catch((error) => checkErrorResponse(error)),
    {
      enabled: true,
      ...options
    }
  )
}

export const useAppointmentsInfoByClient = (user, options = {}) => {
  return useQuery(
    ['appointmentsByUser', user],
    () =>
      // user &&
      axios
        .get(envApiUrl + '/appointments/bycustomerid', {
          headers: {
            'Content-Type': 'application/json',
            id: user?.id,
            customerid: user.customerId,
            username: user?.username
            // detailed: true
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .catch(function (error) {
          if (error.response) {
            console.log('AAA: ', error.response.data) // Message
            console.log(error.response.status) // Error code number
            console.log(error.response.headers)
          }
          if (error.response?.status === 403) {
            console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
            window.location.href = '/signout'
          }
        }),
    {
      enabled: !!user,
      ...options
    }
  )
}

export const useAppointmentsInfoByCustomerId = (customerId, options = {}) => {
  return useQuery(
    ['appointmentsByCustomerId', customerId],
    () =>
      customerId &&
      axios
        .get(envApiUrl + '/appointments/bycustomerid', {
          headers: {
            'Content-Type': 'application/json',
            customerid: customerId
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!customerId,
      ...options
    }
  )
}

export const useAppointmentsInfoById = (appointmentId, options = {}) => {
  const { detailed } = options
  return useQuery(
    ['appointmentsById', appointmentId],
    () =>
      appointmentId &&
      axios
        .get(envApiUrl + '/appointments/byappointmentid', {
          headers: {
            'Content-Type': 'application/json',
            id: appointmentId,
            detailed
            // username: user?.username
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!appointmentId,
      ...options
    }
  )
}

export const useAppointmentsInfoByVendorId = (byvendorid, options = {}) => {
  return useQuery(
    ['appointmentsByVendorId', byvendorid],
    () =>
      byvendorid &&
      axios
        .get(envApiUrl + '/appointments/byvendorid', {
          headers: {
            'Content-Type': 'application/json',
            id: byvendorid
            // username: user?.username
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!byvendorid,
      ...options
    }
  )
}

export const useAnswersByAppointmentId = (appointmentid, options = {}) => {
  return useQuery(
    ['useAnswersByAppointmentId', appointmentid],
    () =>
      appointmentid &&
      axios
        .get(envApiUrl + '/answer', {
          headers: {
            'Content-Type': 'application/json',
            id: appointmentid
            // username: user?.username
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!appointmentid,
      ...options
    }
  )
}

export const useQuestionsByCustomerId = (customerid, options = {}) => {
  return useQuery(
    ['useQuestionsByCustomerId', customerid],
    () =>
      customerid &&
      axios
        .get(envApiUrl + '/question/bycustomerid', {
          headers: {
            'Content-Type': 'application/json',
            id: customerid
            // username: user?.username
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: !!customerid,
      ...options
    }
  )
}
export const useUserInfo = (options = {}) => {
  const { data: isLoggedIn, isLoading } = useIsLoggedIn()
  return useQuery(
    ['userInfo', isLoggedIn],
    () =>
      // user &&
      axios
        .get(envApiUrl + '/users/', {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .catch(function (error) {
          if (error.response) {
            console.log('AAA: ', error.response.data) // Message
            console.log(error.response.status) // Error code number
            console.log(error.response.headers)
          }
          if (error.response?.status === 403) {
            console.log('TRYING TO SIGN OUT AND REDIRECT DUE TO ERROR RESPONSE FROM SERVER')
            window.location.href = '/signout'
          }
        }),
    {
      enabled: !!isLoggedIn && !isLoading,
      ...options
    }
  )
}
export const useGetAllAgents = (options = {}) => {
  return useQuery(
    ['getAllUsers'],
    () =>
      // user &&
      axios
        .get(envApiUrl + '/users/all', {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status) // Error code number
            console.log(error.response.headers)
            message.error(error.response.headers)
          }
          if (error.response?.status === 403) {
            window.location.href = '/signout'
          }
        }),
    {
      enabled: true,
      ...options
    }
  )
}
export const useIsLoggedIn = (options = {}) => {
  return useQuery(
    ['userInfo'],
    () =>
      // user &&
      axios
        .get(envApiUrl + '/users/isLoggedIn', {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: true,
      ...options
    }
  )
}

export const useLogs = (options = {}) => {
  return useQuery(
    ['logs'],
    () =>
      // user &&
      axios
        .get(envApiUrl + '/logs', {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data),
    {
      enabled: true,
      ...options
    }
  )
}
// export const useUserInfoByCookie = (cookie, options = {}) => {
//   const dispatch = useDispatch()
//   const appVariables = useSelector((state) => state.appvariables)
//   const [cookies, setCookie, removeCookie] = useCookies(['salesplanr-login'])
//   return useQuery(
//     ['userInfo', cookie],
//     () =>
//       cookie?.username &&
//       axios
//         .get(envApiUrl + '/users/', {
//           headers: {
//             'Content-Type': 'application/json',
//             username: cookie?.username
//           },
//           withCredentials: true,
//           xsrfCookieName: 'csrftoken_testtest',
//           xsrfHeaderName: 'X-CSRFToken'
//         })
//         .then((response) => {
//           console.log('Header signin response: ', response)
//           dispatch(storeAppvariables('user', response.data))
//           setCookie('username', response.data.username, { path: '/' })
//           setCookie('userData', response.data, { path: '/' })
//           return response.data
//         })
//         .catch((err) => console.log('Signin error: ', err.response?.data?.message, err.response)),
//     {
//       enabled: !!cookie,
//       ...options
//     }
//   )
// }

// export const toggleFeedbackMeetingCompleted = (feedbackItem, refetch) => {
//   const { id, completedMeeting } = feedbackItem
//   const data = {
//     completedMeeting: !completedMeeting
//   }
//   console.log('onCreateFeedback values of form: ', data)
//   axios
//     .put(process.env.REACT_APP_LOCAL === "true" ?  "http://localhost:3000/api" : envApiUrl  + '/feedback/', data, {
//       headers: {
//         'Content-Type': 'application/json',
//         id: id
//       },
//       withCredentials: true,
//       xsrfCookieName: 'csrftoken_testtest',
//       xsrfHeaderName: 'X-CSRFToken'
//     })
//     .then((response) => {
//       console.log('Toggle Feedback: response: ', response)
//       return response.data
//     })
//     .finally(() => {
//       refetch()
//     })

//     .catch((err) => console.error('Toggle Feedback error: ', err.response?.data?.message, err))
// }

export const toggleMeetingApproved = (appointment, refetch) => {
  const { id, approved } = appointment
  const data = {
    approved: !approved
  }
  console.log('toggleMeetingApproved values : ', data, appointment)
  axios
    .put(envApiUrl + '/appointments/approved', data, {
      headers: {
        'Content-Type': 'application/json',
        id
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('Toggle Appointment: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch()
    })

    .catch((err) => {
      console.error('Toggle Appointment error: ', err.response?.data?.message, err.response)
      message.error(err.response?.data?.message)
    })
}

export const deleteAppointment = (appointmentId, refetch) =>
  appointmentId &&
  axios
    .delete(envApiUrl + '/appointments/', {
      headers: {
        'Content-Type': 'application/json',
        id: appointmentId
        // username: user?.username
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteAppointment: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch?.()
    })
    .catch((err) => {
      console.error('deleteAppointment error: ', err.response?.data?.message, err.response)
      message.error(err.response?.data?.message)
    })

export const deleteUser = (userId, refetch) =>
  userId &&
  axios
    .delete(envApiUrl + '/users/', {
      headers: {
        'Content-Type': 'application/json',
        id: userId
        // username: user?.username
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteUser: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch()
    })
    .catch((err) => {
      console.error('deleteUser error: ', err.response?.data?.message, err.response)
      message.error(err.response?.data?.message)
    })

export const deleteCustomer = (id, refetch) =>
  id &&
  axios
    .delete(envApiUrl + '/customers/', {
      headers: {
        'Content-Type': 'application/json',
        id
      },
      withCredentials: true,
      xsrfCookieName: 'csrftoken_testtest',
      xsrfHeaderName: 'X-CSRFToken'
    })
    .then((response) => {
      console.log('deleteCustomer: response: ', response)
      return response.data
    })
    .finally(() => {
      refetch()
    })
    .catch((err) => {
      console.error('deleteCustomer error: ', err.response?.data?.message, err.response)
      message.error(err.response?.data?.message)
    })

export const deleteVendor = (vendorId, refetch) => {
  return (
    vendorId &&
    axios
      .delete(envApiUrl + '/vendors/', {
        headers: {
          'Content-Type': 'application/json',
          id: vendorId
          // username: user?.username
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('deleteVendor: response: ', response)
        return response.data
      })
      .finally(() => {
        refetch()
      })
      .catch((err) => {
        console.error('deleteVendor error: ', err.response?.data?.message, err.response)
        message.error(err.response?.data?.message)
      })
  )
}

export const handleCreateAppointment = (data, user, onOk) => {
  console.log('handleCreateAppointment data', data)
  user.id &&
    axios
      .post(envApiUrl + '/appointments', data, {
        headers: {
          'Content-Type': 'application/json',
          userId: user.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create appointment: response: ', response)
        return response.data
      })
      .finally(() => onOk())
      .catch((err) => {
        console.error('Create appointment error: ', err.response?.data?.message, err.response)
        message.error(err.response?.data?.message)
      })
}

export const handleUpdateAppointment = (data, appointment, onOk) => {
  console.log('handleUpdateAppointment data', data)
  appointment.id &&
    axios
      .put(envApiUrl + '/appointments', data, {
        headers: {
          'Content-Type': 'application/json',
          id: appointment.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Update appointment: response: ', response)
        return response.data
      })
      .finally(() => onOk())
      .catch((err) => {
        console.error('Update appointment error: ', err.response?.data?.message, err.response)
        message.error(err.response?.data?.message)
      })
}
