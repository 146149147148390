import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Tooltip, Radio, Select, DatePicker, Row, Col, ConfigProvider } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import {
  handleUpdateAppointment,
  useAnswersByAppointmentId,
  useLeadsInfoByUser,
  useQuestionsByCustomerId,
  useVendorInfoByUser
} from '../../fetchHooks/useFetchHooks'
import moment from 'moment-timezone'
import { PlusOutlined } from '@ant-design/icons'
import CreateLeadForm from '../../CreateLead/CreateLeadForm'
import TextArea from 'antd/lib/input/TextArea'
import 'moment/locale/en-gb'
import locale from 'antd/es/locale/en_GB'
import { StyledButton, StyledLabel, StyledTextArea, StyledTextInput } from '../../styles'
import { ModalButtons } from '../CreateAgent/CreateAgentForm'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import './EditAppointment.scss'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})
function range(start, end) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

function disabledDateTime() {
  return {
    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23], // () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(0, 0),
    disabledSeconds: () => range(0, 0)
  }
}
function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day')
}

const EditAppointment = ({ onOk, onClose, showEditAppointmentModal, user, appointment }) => {
  const [form] = Form.useForm()

  const [createLeadModalvisible, setCreateLeadModalVisible] = useState(false)
  const [isDigital, setIsDigital] = useState(appointment?.isDigital)
  const { data: vendorInfo, isLoading, error } = useVendorInfoByUser(user)
  const { data: leadsInfo, refetch: refetchLeads } = useLeadsInfoByUser(user)
  const { data: questions, refetch: refetchQuestions } = useQuestionsByCustomerId(form.getFieldValue(['customerId']))

  useEffect(() => {
    appointment && console.log('Edit appointment: ', appointment)
  }, [appointment])

  if (!showEditAppointmentModal) return
  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error...</p>

  const onCreateLead = (values) => {
    console.log('onCreateLeadReceived values of form: ', values)
    const data = {
      ...values,
      vendorId: user.vendorId,
      username: user.username
    }
    axios
      .post(process.env.REACT_APP_API_URL + '/leads', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create Lead: response: ', response)
        return response.data
      })
      .finally(() => {
        refetchLeads()
        setCreateLeadModalVisible(false)
      })
      .catch((err) => console.error('Signin error: ', err.response?.data?.message, err.response))
  }

  const onFinish = (values) => {
    console.log('Edit: Entering onFinish: ', values)
    if (values.customerId) {
      const vals = {
        ...values,
        meetingDate: values.meetingDate?.clone?.().tz('Europe/Oslo'),
        meetingIsDigital: isDigital
        // meetingTime: values.meetingTime.format('HH:mm')
      }
      questions?.map((item, index) => (item.answer = values['question' + index]))
      const data = {
        ...vals,
        vendorId: user.vendorId,
        userId: user.id,
        questions,
        customer: vendorInfo?.customers?.find((item) => item.id === form.getFieldValue(['customerId'])),
        lead: leadsInfo?.find((item) => item.id === form.getFieldValue(['leadId'])),
        vendor: vendorInfo,
        user
        // answers
      }
      console.log('Update Appointment: Received values of form:', data)
      handleUpdateAppointment(data, appointment, onOk)
    } else console.log('No values.customerId: ', form.getFieldValue(['customerId']))
  }

  return (
    // <Modal
    //   title="Edit appointment"
    //   visible={showEditAppointmentModal}
    //   onOk={form.submit}
    //   // confirmLoading={confirmLoading}
    //   onCancel={onClose}
    //   // bodyStyle={{ minHeight: '80vh' }}
    //   width="75vw"
    //   centered
    //   destroyOnClose
    // >
    <Modal
      title="Edit appointment"
      visible={showEditAppointmentModal}
      // bodyStyle={{ minHeight: '80vh' }}
      onCancel={onClose}
      bodyStyle={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="modalStyle"
      width="720px"
      footer={null}
      centered
      destroyOnClose
    >
      <Form
        // labelCol={{
        //   span: 14
        // }}
        // wrapperCol={{
        //   span: 14
        // }}
        form={form}
        layout="vertical"
        initialValues={{
          size: 'default',
          customerId: appointment.customerId,
          leadId: appointment.leadId,
          meetingNote: appointment.meetingNote,
          meetingDate: moment(appointment.meetingDate).clone().tz('Europe/Oslo'),
          meetingLength: appointment.meetingLength
          // meetingIsDigital: false,
          // customerId: vendorInfo?.customers.slice(-1)?.id
        }}
        //   onValuesChange={onFormLayoutChange}
        size={'default'}
        onFinish={onFinish}
      >
        <CreateLeadForm visible={createLeadModalvisible} onCreate={onCreateLead} onCancel={() => setCreateLeadModalVisible(false)} />

        <div className="edit-appointment-container">
          <div className="edit-appointment-header">
            <h2>Edit appointment </h2>
          </div>

          <div className="edit-appointment-title ">
            <h2>Please fill in the following information:</h2>
          </div>

          {/* --------------- CUSTOMER --------------- */}
          <div className="edit-appointment-customer ">
            <Form.Item
              name="customerId"
              label={<StyledLabel>Select which company the meeting is being booked for</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please select which company you are booking this meeting for'
                }
              ]}
            >
              <Select
                showSearch
                onChange={(e) => refetchQuestions()}
                placeholder="Select a customer"
                optionFilterProp="children"
                dropdownStyle={{
                  backgroundColor: 'white',
                  color: 'rgb(53, 58, 69)',
                  paddingLeft: '8px',
                  borderRadius: '10px',
                  border: '1px solid rgb(53, 58, 69)'
                }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled
              >
                {vendorInfo?.customers?.map((customer) => (
                  <Select.Option value={customer.id} key={customer.id}>
                    {customer.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          {/* --------------- LEAD --------------- */}
          <div className="edit-appointment-lead">
            <Form.Item
              name="leadId"
              label={<StyledLabel>Select company meeting is being booked with</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please select which company you are booking this meeting with'
                }
              ]}
            >
              <Select
                showSearch
                placeholder="Select a lead"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {leadsInfo?.map((lead) => (
                  <Select.Option value={lead.id} key={lead.id}>
                    {lead.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          {/* --------------- ADD LEAD --------------- */}
          <div className="edit-appointment-addleadbtn">
            <Tooltip title="Add new lead">
              <StyledButton
                type="primary"
                // style={{ marginTop: '30px' }}
                // style={{ alignSelf: 'flex-end', marginBottom: '25px' }}
                className="addleadbtn"
                shape="round"
                size="default"
                onClick={() => setCreateLeadModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Add
              </StyledButton>
            </Tooltip>
          </div>

          {/* --------------- MEETING DATE --------------- */}
          <div className="edit-appointment-date">
            <ConfigProvider locale={locale}>
              <Form.Item
                name="meetingDate"
                label={<StyledLabel>Date and Time</StyledLabel>}
                rules={[
                  {
                    required: true,
                    message: 'Please choose meeting date and time'
                  }
                ]}
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  placeholder="Select date / time"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                  showTime={{
                    initialValue: moment('08:00', 'HH:mm'),
                    minuteStep: 15,
                    format: 'HH:mm',
                    allowClear: false,
                    hideDisabledOptions: true,
                    showNow: false
                  }}
                />
              </Form.Item>
            </ConfigProvider>
          </div>

          {/* --------------- MEETING LENGTH --------------- */}
          <div className="edit-appointment-meetinglength">
            <Form.Item
              name="meetingLength"
              label={<StyledLabel>Meeting length</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please choose meeting length'
                }
              ]}
            >
              <Select
                // showSearch
                // addonBefore="Lead company"

                placeholder="Select duration"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Select.Option value={30}>30 min</Select.Option>
                <Select.Option value={60}>60 min</Select.Option>
              </Select>
            </Form.Item>
          </div>

          {/* --------------- IS DIGITAL --------------- */}
          <div className="edit-appointment-digital">
            <Form.Item name="meetingIsDigital" className="collection-create-form_last-form-item">
              <>
                <Checkbox checked={isDigital} onChange={(e) => setIsDigital(e.target.checked)}>
                  <StyledLabel>Digital meeting</StyledLabel>
                </Checkbox>
                <Checkbox checked={!isDigital} onChange={(e) => setIsDigital((prev) => !prev)}>
                  <StyledLabel>Physical meeting</StyledLabel>
                </Checkbox>
              </>
            </Form.Item>
          </div>

          {/* --------------- NOTE --------------- */}
          <div className="edit-appointment-note">
            <Form.Item
              name="meetingNote"
              label={<StyledLabel>Meeting note</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please write a meeting note'
                }
              ]}
            >
              <StyledTextArea rows={4} />
            </Form.Item>
          </div>
          <ModalButtons
            onOk={form.submit}
            okText="Save"
            // confirmLoading={confirmLoading}
            onCancel={onClose}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default EditAppointment
