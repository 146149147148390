import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Checkbox, Input, Button } from "antd"

// Background: rgb(53, 58, 69);

export const BannerText = styled.h1`
  position: absolute;
  font-size: 96px;
  top: 25%;
  left: 35vw;
  color: darkcyan;
`
export const LoginWrapper = styled.div`
  margin-top: 8rem;
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  max-height: 100vh;
  margin-top: -56px; */
  height: 100%;
  // Tablet:
  @media only screen and (min-width: 768px) {
  }

  // Desktop:
  @media only screen and (min-width: 1024px) {
    /* margin: 0 auto; */
    /* width: 100%; */
    /* max-width: 1280px; */
  }
`

export const RoundedInput = styled(Input)`
  border-radius: 25px;
`
export const StyledCheckbox = styled(Checkbox)`
  color: white;
  ${(props) =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
        border-radius: 25px;
      }
    `}
`

export const StyledSwitch = styled.div`
  .ant-switch-checked.ant-switch-checked.ant-switch-checked {
    background: rgb(180, 247, 200);
  }
`
export const StyledButton = styled(Button)`
  background: rgb(180, 247, 200);
  color: rgb(53, 58, 69);
  border-radius: 25px;
  width: 7rem;
`
