const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "STOREUSER":
      // let user = { ...state.user }
      // user = action.payload
      return Object.assign({}, state, action.payload)
    // case 'DECREMENT':
    //     return state - action.payload
    default:
      return state
  }
}

export default userReducer
