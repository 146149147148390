const appvariablesReducer = (state = { DEBUG: false, headerMenuSelection: "home" }, action) => {
  switch (action.type) {
    case "STOREAPPVARIABLES": {
      const appvariables = { ...state.appvariables }
      appvariables[action.payload.name] = action.payload.value
      return Object.assign({}, state, {
        [action.payload.name]: action.payload.value
      })
    }

    default:
      return state
  }
}

export default appvariablesReducer
