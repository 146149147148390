import axios from 'axios'
import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { envApiUrl } from '../fetchHooks/useFetchHooks'
import { storeAppvariables } from '../redux/actions'

const Signout = () => {
  const dispatch = useDispatch()

  const { isLoading, isError, isSuccess } = useQuery('userData', () => {
    return axios
      .post(
        envApiUrl + '/auth/signout',
        {
          // username: 'intercom'
          // password: "heihei",
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        }
      )
      .then((response) => {
        console.log('response: ', response)
        dispatch(storeAppvariables('user', {}))
        return response.data
      })
  })

  if (isLoading) {
    return <h1>Loading...</h1>
  }

  if (isError) {
    return <h1>Something went wrong</h1>
  }

  if (isSuccess) {
    window.location.href = '/'
  }

  return <ul>{isSuccess && <p>Signed out</p>}</ul>
}

export default Signout
