import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Select, message } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useBrregInfo, useGetAllVendors, useVendorInfoByUser } from '../../fetchHooks/useFetchHooks'
import { FastForwardOutlined } from '@ant-design/icons'
import { StyledButton, StyledLabel, StyledTextInput, StyledTextInputNumber } from '../../styles'
import { ModalButtons } from '../CreateAgent/CreateAgentForm'

const CreateCustomerForm = ({ visible, onCreate, onCancel, vendorId, vendorInfo }) => {
  visible && console.log('CreateCustomerForm: ')
  const [form] = Form.useForm()
  const [orgNumber, setOrgNumber] = useState('')
  const { data: orgData } = useBrregInfo(orgNumber)

  useEffect(() => {
    !!orgData &&
      form.setFieldsValue({
        companyName: orgData?.navn,
        companyAddress: `${orgData?.forretningsadresse?.adresse[0]} ${orgData?.forretningsadresse?.postnummer} ${orgData?.forretningsadresse?.poststed}`
      })
  }, [orgData, form])

  useEffect(() => {
    orgNumber && console.log('orgNumber: ', orgNumber)
  }, [orgNumber])

  useEffect(() => {
    vendorInfo && console.log('vendorInfo: ', vendorInfo)
  }, [vendorInfo])

  const handleCreateCustomer = (values) => {
    const data = { ...values, vendorId: values.vendorId ? values.vendorId : vendorId }
    console.log('handleCreateCustomer data', data)
    axios
      .post(process.env.REACT_APP_API_URL + '/customers', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create customer: response: ', response)
        return response.data
      })
      .finally(() => onCreate())
      .catch((err) => {
        console.error('Create customer error: ', err.response?.data?.message, err.response)
        message.error(err.response?.data?.message)
      })
  }
  return (
    <Modal
      visible={visible}
      title="Create a new customer"
      okText="Create"
      bodyStyle={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      onCancel={onCancel}
      cancelText="Cancel"
      className="modalStyle"
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={
          {
            // companyOrgNumber: '928676218'
          }
        }
      >
        <div className="create-customer-container">
          <div className="create-customer-header">
            <h2>Create new customer </h2>
          </div>
          <div className="create-customer-org">
            <Form.Item
              name="companyOrgNumber"
              label={<StyledLabel>Organization number</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please input the company organisation number',
                  min: 9,
                  max: 9
                }
              ]}
            >
              <StyledTextInputNumber maxLength={9} stringMode placeholder="Please type 9 digits org number" />
            </Form.Item>
          </div>
          {vendorInfo?.length && (
            <div className="create-customer-vendor">
              <Form.Item
                name="vendorId"
                label={<StyledLabel>Select vendor</StyledLabel>}
                rules={[
                  {
                    required: true,
                    message: 'Please select vendor'
                  }
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select vendor"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {vendorInfo?.map(({ id, companyName }) => (
                    <Select.Option value={id} key={id}>
                      {companyName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          <div className="create-customer-orgbtn">
            <StyledButton
              type="primary"
              shape="round"
              size="default"
              onClick={() => {
                console.log('Setting organisation number: ', form.getFieldValue(['companyOrgNumber']))
                setOrgNumber(form.getFieldValue(['companyOrgNumber']))
              }}
              icon={<FastForwardOutlined />}
            >
              Autofill
            </StyledButton>
          </div>
          <div className="create-customer-name">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the company name'
                }
              ]}
              label={<StyledLabel>Company name</StyledLabel>}
              name="companyName"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-customer-adr">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the company address'
                }
              ]}
              label={<StyledLabel>Company address</StyledLabel>}
              name="companyAddress"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-customer-contactperson">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the company contact person'
                }
              ]}
              label={<StyledLabel>Contact person</StyledLabel>}
              name="companyContactPerson"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-customer-contactposition">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the position of the contact person above'
                }
              ]}
              label={<StyledLabel>Contact position</StyledLabel>}
              name="companyContactPosition"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-customer-phone">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input phone number'
                }
              ]}
              label={<StyledLabel>Phone number</StyledLabel>}
              name="companyPhoneNumber"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-customer-email">
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input a valid email address'
                }
              ]}
              label={<StyledLabel>Email address</StyledLabel>}
              name="companyEmail"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <ModalButtons
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  handleCreateCustomer(values)
                  onCreate(values)
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default CreateCustomerForm
