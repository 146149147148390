import React, { useState } from 'react'
import { Button, Space, Tooltip, Table, Popconfirm } from 'antd'
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { deleteVendor, useAppointmentsInfoByVendorId, useCustomers, useGetAllVendors } from '../../fetchHooks/useFetchHooks'
import { LinkButton } from '../../pages/HomePage/styles'
// import VendorDetailsModal from '../VendorDetailsModal/VendorDetailsModal'
import { StyledButton, TableAddButton, TableContainer } from '../../styles'
import CreateVendorForm from '../CreateVendor/CreateVendorForm'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

const VendorsTableMaster = ({ user }) => {
  const [showVendorDetailsModal, setShowVendorDetailsModal] = useState(false)
  const [showCreateVendorModal, setShowCreateVendorModal] = useState(false)
  const { data: vendors, isLoading, isError, refetch } = useGetAllVendors()
  const queryClient = useQueryClient()

  useEffect(() => {
    vendors && console.log('VendorsTableMaster vendors: ', vendors)
  }, [vendors])

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      key: 'id',
      render: (id, item) => <LinkButton onClick={() => setShowVendorDetailsModal(item.id)}>{id}</LinkButton>
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      key: 'companyName',
      render: (companyName, item) => <LinkButton onClick={() => setShowVendorDetailsModal(item.id)}>{companyName}</LinkButton>
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, item) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            size="default"
            icon={<EyeOutlined />}
            onClick={() => setShowVendorDetailsModal(item.id)}
            ghost
            className="edit-button"
          />
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() =>
              deleteVendor(item.id, () => {
                refetch()
                queryClient.invalidateQueries(['getAllUsers'])
                queryClient.invalidateQueries(['AgentsByVendorId'])
                queryClient.invalidateQueries(['useCustomers'])
              })
            }
            onCancel={() => console.log('Canceled')}
            okText="Yes"
            cancelText="No"
          >
            <StyledButton type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
          </Popconfirm>
        </Space>
      )
    }
  ]
  if (isLoading) return <h1>Loading...</h1>

  return (
    <TableContainer>
      <h2>Vendors:</h2>
      <Table dataSource={vendors} columns={columns} rowKey="id" />

      <Tooltip title="Add new customer">
        <TableAddButton type="primary" shape="round" size="default" onClick={() => setShowCreateVendorModal(true)} icon={<PlusOutlined />}>
          Add vendor
        </TableAddButton>
      </Tooltip>

      <CreateVendorForm
        visible={showCreateVendorModal}
        onCreate={() => {
          setShowCreateVendorModal(false)
          refetch()
        }}
        onCancel={() => setShowCreateVendorModal(false)}
        vendorId={user.vendorId}
      />
      {/* 
      {showVendorDetailsModal && (
        <VendorDetailsModal
          show={!!showVendorDetailsModal}
          customerId={showVendorDetailsModal}
          customers={customers}
          onClose={() => setShowVendorDetailsModal(false)}
          refetch={refetch}
        />
      )} */}
    </TableContainer>
  )
}

export default VendorsTableMaster
