import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import Select, { components } from 'react-select'
import { StyledLabel, StyledLabelSpan } from '../../styles'
import { isMobile } from '../../components/Navbar/Navbar'

const FilterSelectComponent = ({ appointments, onChange }) => {
  const customStyles = {
    indicatorsContainer: () => ({
      '.customerFilterSelect': {
        '&__dropdown-indicator': {
          color: 'rgb(53, 58, 69)',
          paddingLeft: '4px'
        }
      }
    }),

    option: (provided, state) => ({
      // HVERT ELEMENT I LISTEN
      ...provided,
      borderBottom: '1px solid var(--grey-medium)',
      color: 'rgb(53, 58, 69)', // state.isSelected ? 'red' : 'blue',
      backgroundColor: state.isSelected ? 'rgb(180, 247, 200)' : 'rgb(180, 247, 200,0.8)', // STANDARD ER rgb(180, 247, 200)
      '&:hover': {
        backgroundColor: 'rgb(180, 247, 202)'
      }
    }),

    control: (provided, state) => {
      // console.log('Control: ', provided, state);
      return {
        // HELE FELTET PÅ TOPP
        // none of react-select's styles are passed to <Control />
        ...provided,
        border: '1px solid var(--seagreen-medium)',
        borderRadius: '25px',
        color: 'rgb(53, 58, 69)',
        boxShadow: 'none',
        background: 'rgb(180, 247, 200)',
        // minWidth: '20rem',
        minWidth: isMobile ? '100%' : '20rem',
        // opacity: state.menuIsOpen ? '0' : '1',
        '&:hover': {
          border: '1px solid var(--seagreen-medium)'
        },
        animation: 'fadeIn .3s ease-in-out'
        // width: '376px',
      }
    },
    menuList: (provided, state) => ({
      ...provided,
      border: '2px solid var(--seagreen-dark)',
      borderRadius: '5px',
      padding: '0',
      '::-webkit-scrollbar': {
        borderRadius: '6px',
        width: '6px',
        height: '0px'
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '6px',
        background: 'rgb(180, 247, 200) '
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '6px',
        background: 'rgb(180, 247, 200)'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: 'rgb(190, 247, 200)'
      }
    }),
    singleValue: (provided, state) => {
      // console.log('provided: ', provided, state);
      // VERDIEN SOM ER VALGT PÅ TOPPEN
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      const backgroundColor = 'rgb(180, 247, 200)' // state.isSelected ? 'red' : 'green';

      return { ...provided, opacity, transition, backgroundColor }
    }
  }

  const formatOptionLabel = (props) => {
    const { label } = props
    return (
      <div className="customer-filter-select-option">
        <StyledLabelSpan>{label}</StyledLabelSpan>
      </div>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DownOutlined />
      </components.DropdownIndicator>
    )
  }
  // const Menu = (props) => <components.Menu {...props} className="payment-select-menu" />

  const options = [
    {
      value: '',
      label: 'All'
    },
    ...appointments.map((item) => {
      return {
        ...item?.customer,
        value: item.customer?.companyName,
        label: item.customer?.companyName
      }
    })
  ]

  return (
    <div>
      <Select
        defaultValue={options[0]}
        styles={customStyles}
        // onInputChange={(item) => console.log('Change: ', item)}
        onChange={(item) => onChange(item.value)}
        closeMenuOnScroll
        isSearchable={false}
        menuPlacement="top"
        // isClearable
        // onChange={onChange}
        placeholder={
          <div className="customer-filter-select-option">
            <StyledLabel>{options[0]}</StyledLabel>
          </div>
        }
        components={{ DropdownIndicator }}
        // components={{ DropdownIndicator, Menu, MenuList: SelectMenuButton }}
        formatOptionLabel={formatOptionLabel}
        // isSearchable
        // name="accountNumber.value"
        options={[...new Map(options.map((item) => [item.label, item])).values()]}
        classNamePrefix="customerFilterSelect"
        // isLoading={isLoading}
        // options={accounts?.map((item) => {
        //     return { label: item.accountNumber.value, value: item.accountNumber.value };
        // })}
        // style={{ width: '100%' }}
      />
    </div>
  )
}

export default FilterSelectComponent
