import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useBrregInfo } from '../fetchHooks/useFetchHooks'
import { FastForwardOutlined } from '@ant-design/icons'
import { StyledButton, StyledLabel, StyledTextInput, StyledTextInputNumber } from '../styles'
import { ModalButtons } from '../components/CreateAgent/CreateAgentForm'

const CreateLeadForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm()
  const [orgNumber, setOrgNumber] = useState('')
  const { data: orgData } = useBrregInfo(orgNumber)

  useEffect(() => {
    orgData && console.log('useEffect orgData: ', orgData)
  }, [orgData])
  useEffect(() => {
    orgNumber && console.log('useEffect orgNumber: ', orgNumber)
  }, [orgNumber])

  useEffect(() => {
    !!orgData &&
      form.setFieldsValue({
        companyName: orgData?.navn,
        companyAddress: `${orgData?.forretningsadresse?.adresse[0]} ${orgData?.forretningsadresse?.postnummer} ${orgData?.forretningsadresse?.poststed}`
      })
  }, [orgData, form])

  return (
    <Modal
      visible={visible}
      okText="Create"
      bodyStyle={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      onCancel={onCancel}
      cancelText="Cancel"
      className="modalStyle"
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          companyOrgNumber: ''
        }}
      >
        <div className="create-lead-container">
          <div className="create-lead-header">
            <h2>Create new lead </h2>
          </div>
          <div className="create-lead-org">
            <Form.Item
              name="companyOrgNumber"
              label={<StyledLabel>Organization number</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please input the company organisation number',
                  min: 9,
                  max: 9
                }
              ]}
            >
              <StyledTextInputNumber maxLength={9} stringMode placeholder="Please type 9 digits org number" />
            </Form.Item>
          </div>
          <div className="create-lead-orgbtn">
            <StyledButton
              type="primary"
              shape="round"
              size="default"
              onClick={() => {
                console.log('Setting organisation number: ', form.getFieldValue(['companyOrgNumber']))
                setOrgNumber(form.getFieldValue(['companyOrgNumber']))
              }}
              icon={<FastForwardOutlined />}
            >
              Autofill
            </StyledButton>
          </div>
          <div className="create-lead-name">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the company name'
                }
              ]}
              label={<StyledLabel>Company name</StyledLabel>}
              name="companyName"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-lead-adr">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the company address'
                }
              ]}
              label={<StyledLabel>Company address</StyledLabel>}
              name="companyAddress"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-lead-contactperson">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the company contact person'
                }
              ]}
              label={<StyledLabel>Contact person</StyledLabel>}
              name="companyContactPerson"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-lead-contactposition">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input the position of the contact person above'
                }
              ]}
              label={<StyledLabel>Contact position</StyledLabel>}
              name="companyContactPosition"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-lead-phone">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input phone number'
                }
              ]}
              label={<StyledLabel>Phone number</StyledLabel>}
              name="companyPhoneNumber"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <div className="create-lead-email">
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input a valid email address'
                }
              ]}
              label={<StyledLabel>Email address</StyledLabel>}
              name="companyEmail"
            >
              <StyledTextInput />
            </Form.Item>
          </div>
          <ModalButtons
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  onCreate(values)
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default CreateLeadForm
