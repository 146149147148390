import React from 'react'
import './App.scss'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/HomePage/Home'
import Signin from './pages/Signin'
import Signout from './pages/Signout'
import Login from './pages/LoginPage/Login'
import 'antd/dist/antd.min.css'
import ShowLogs from './pages/ShowLogs'

const queryClient = new QueryClient()

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/logs" element={<ShowLogs />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}
