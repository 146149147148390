import React from 'react'

import UsersTableMaster from '../../components/AgentsTable/UsersTableMaster'
import VendorsTableMaster from '../../components/VendorsTableMaster/VendorsTableMaster'
import CustomersTableMaster from '../../components/CustomersTable/CustomersTableMaster'

const MasterInfo = ({ user }) => {
  if (!user?.username) return <p>Error fetching agent info</p>

  return (
    <div>
      <VendorsTableMaster user={user} />
      <CustomersTableMaster user={user} />
      <UsersTableMaster user={user} />
    </div>
  )
}
export default MasterInfo
