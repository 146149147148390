import React, { useEffect } from 'react'
import { useLogs } from '../fetchHooks/useFetchHooks'
import { AppContainer } from '../styles'
import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'

const ShowLogs = () => {
  const { data: logs, isLoading, isError } = useLogs()

  useEffect(() => {
    logs && console.log('logs: ', typeof logs)
    // logs && console.log("logs: ", JSON.parse(logs))
    // logs && console.log("logs: ", Object.entries(JSON.parse(logs)))
  }, [logs])

  if (isLoading) {
    return <h1>Loading...</h1>
  }

  if (isError) {
    return <h1>Something went wrong</h1>
  }

  // All the JSX will go inside this return
  return (
    <AppContainer>
      <Layout className="layout-default">
        <Content className="layout-default" style={{ margin: '24px 16px 0' }}>
          <h2>Logs:</h2>
          <ul>
            {logs
              .split('\n')
              .reverse()
              .map((item, index) => (
                <p key={index}>
                  {index}: {item}
                </p>
              ))}
            {/* {logs.split("\n").reverse().map(item => console.log(item?.message))} */}
            {/* {logs.split("\n").reverse().map(item => <p>{JSON.parse(JSON.stringify(item))?.message}</p>)} */}
          </ul>
        </Content>
      </Layout>
    </AppContainer>
  )
}

export default ShowLogs
