import React, { useState } from 'react'
import { Button, Space, Tooltip, Table } from 'antd'
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import {
  deleteCustomer,
  useAppointmentsInfoByVendorId,
  useCustomers,
  useGetAllCustomers,
  useGetAllVendors
} from '../../fetchHooks/useFetchHooks'
import { LinkButton } from '../../pages/HomePage/styles'
import CustomerDetailsModal from '../CustomerDetailsModal/CustomerDetailsModal'
import { StyledButton, TableAddButton, TableContainer } from '../../styles'
import CreateCustomerForm from '../CreateCustomer/CreateCustomerForm'
import Popconfirm from 'antd/es/popconfirm'
import { useQueryClient } from 'react-query'

const CustomersTableMaster = ({ user }) => {
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState(false)
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false)
  const { data: customers, isLoading, isError, refetch } = useGetAllCustomers()
  const { data: vendorInfo } = useGetAllVendors()
  const queryClient = useQueryClient()

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      key: 'id',
      render: (id, item) => <LinkButton onClick={() => setShowCustomerDetailsModal(item.id)}>{id}</LinkButton>
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      key: 'companyName',
      render: (companyName, item) => <LinkButton onClick={() => setShowCustomerDetailsModal(item.id)}>{companyName}</LinkButton>
    },
    {
      title: 'Belongs To Vendor',
      dataIndex: 'vendor',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      key: 'vendor',
      render: ({ companyName }, item) => <LinkButton onClick={() => setShowCustomerDetailsModal(item.id)}>{companyName}</LinkButton>
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, item) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            size="default"
            icon={<EyeOutlined />}
            onClick={() => setShowCustomerDetailsModal(item.id)}
            ghost
            className="edit-button"
          />

          <Tooltip title="Delete appointment">
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() =>
                deleteCustomer(item.id, () => {
                  refetch()
                  queryClient.invalidateQueries(['getAllUsers'])
                  queryClient.invalidateQueries(['AgentsByVendorId'])
                })
              }
              onCancel={() => console.log('Canceled')}
              okText="Yes"
              cancelText="No"
            >
              <StyledButton type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
            </Popconfirm>
          </Tooltip>
        </Space>
      )
    }
  ]
  if (isLoading) return <h1>Loading...</h1>

  return (
    <TableContainer>
      <h2>Customers:</h2>
      <Table dataSource={customers} columns={columns} rowKey="id" />

      <Tooltip title="Add new customer">
        <TableAddButton
          type="primary"
          shape="round"
          size="default"
          onClick={() => setShowCreateCustomerModal(true)}
          icon={<PlusOutlined />}
        >
          Add customer
        </TableAddButton>
      </Tooltip>

      <CreateCustomerForm
        visible={showCreateCustomerModal}
        onCreate={() => {
          setShowCreateCustomerModal(false)
          refetch()
          queryClient.invalidateQueries(['getAllUsers'])
          queryClient.invalidateQueries(['AgentsByVendorId'])
        }}
        onCancel={() => setShowCreateCustomerModal(false)}
        vendorId={user.vendorId}
        vendorInfo={vendorInfo}
        disabled={true}
      />

      {showCustomerDetailsModal && (
        <CustomerDetailsModal
          show={!!showCustomerDetailsModal}
          customerId={showCustomerDetailsModal}
          customers={customers}
          onClose={() => setShowCustomerDetailsModal(false)}
          refetch={refetch}
        />
      )}
    </TableContainer>
  )
}

export default CustomersTableMaster
