import _ from 'lodash'

export const formatNumberTwoDigits = (n) =>
  n.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })

export const filterDataByDatapoint = (appointments, datapoint) =>
  _.uniqWith(
    filterData(appointments)((i) => _.get(i, datapoint)),
    _.isEqual
  )

export const filterData = (data) => (formatter) =>
  data.map((item) => ({
    text: formatter(item),
    value: formatter(item)
  }))

export const washUrl = (url) =>
  url?.toLowerCase?.()?.replace(/ /g, '-').replace(/æ/g, 'ae').replace(/ø/g, 'oe').replace(/å/g, 'aa').replace('?', '')
