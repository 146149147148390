export const increment = (number) => {
  return {
    type: "INCREMENT",
    payload: number
  }
}
export const decrement = (number) => {
  return {
    type: "DECREMENT",
    payload: number
  }
}
export const storeUser = (user) => {
  return {
    type: "STOREUSER",
    payload: user
  }
}
export const storeUserInputs = (name, value) => {
  return {
    type: "STOREUSERINPUTS",
    payload: { name, value }
  }
}
export const storeAppvariables = (name, value) => {
  return {
    type: "STOREAPPVARIABLES",
    payload: { name, value }
  }
}
