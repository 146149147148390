import React, { useEffect } from 'react'
import SalesPlanrLogo from '../../images/SalesPlanrLogo.png'
import { useSelector, useDispatch } from 'react-redux'
import { storeAppvariables } from '../../redux/actions'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { StyledLogo, StyledMenuItem } from './Navbar.styles'
import { useUserInfo } from '../../fetchHooks/useFetchHooks'
import { UserOutlined } from '@ant-design/icons'
export const isMobile = window.innerWidth < 500

const Navbar = ({ hidden = false }) => {
  const dispatch = useDispatch()
  // const appVariables = useSelector((state) => state.appvariables)
  const selected = useSelector((state) => state.appvariables.headerMenuSelection)

  const { data: user } = useUserInfo()
  useEffect(() => {
    console.log('user: ', user)
  }, [user])
  const handleSignOut = () => {
    window.location.href = '/signout'
  }

  if (hidden) return
  // const UserInfo = () => {
  //   if (user?.username) {
  //     return (
  //       <StyledUserInfo>
  //         <StyledMenuText>
  //           {user.firstname} {user.lastname} ({user.role})
  //         </StyledMenuText>
  //         <Avatar
  //           // width={'70%'}
  //           src={Me}
  //           size={50}
  //         >
  //           U
  //         </Avatar>
  //       </StyledUserInfo>
  //     )
  //   } else return <span>Not logged in</span>
  // }
  return (
    // <NavbarWrapper>
    <div className="nav-container">
      <div className="nav-logo">
        <Link onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'home'))} to={'/'}>
          <StyledLogo src={SalesPlanrLogo} alt="Salesplanr logo" />
        </Link>
      </div>
      <div className="nav-link1">
        <Link onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'home'))} to={'/'}>
          <StyledMenuItem selected={selected === 'home'}>Home</StyledMenuItem>
        </Link>
      </div>

      <div className="nav-signout">
        <Link onClick={() => dispatch(storeAppvariables('headerMenuSelection', 'home'))} to={'/'}>
          <StyledMenuItem selected={selected === 'sign-out'} onClick={handleSignOut}>
            Sign out
          </StyledMenuItem>
        </Link>
      </div>
      {user?.username ? (
        <>
          <div className="nav-name">
            <StyledMenuItem>
              {user.firstname} ({user.role})
            </StyledMenuItem>
          </div>
          <div className="nav-avatar">
            <Avatar
              size={70}
              style={{ backgroundColor: 'rgba(53, 58, 69, 1)', outline: '3px solid rgb(180, 247, 200)' }}
              icon={<UserOutlined />}
            >
              {/* {user.firstname.slice(0, 1)}
              {user.lastname.slice(0, 1)} */}
            </Avatar>
            {/* <div className="nav-avatar">  // FOR USING PICTURE
            <Avatar
              // width={'70%'}
              src={Me}
              size={70}
              style={{ outline: '3px solid rgb(180, 247, 200)' }}
            >
              U
            </Avatar> */}
          </div>
        </>
      ) : (
        <span>Not logged in</span>
      )}
    </div>
  )
}

export default Navbar
