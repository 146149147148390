import { css } from "@emotion/react"
import styled from "@emotion/styled"

// export const LoginWrapper = styled.div`

export const StyledMenuText = styled.span`
  color: white;
  font-family: 'MontHeavy'
  font-weight: 300;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;

  ${(props) =>
    props.selected
      ? css`
          border-bottom: 1px solid rgb(180, 247, 200);
        `
      : css`
          border-bottom: none;
        `}
`
export const StyledMenuItem = styled.span`
  color: white;
  font-family: FuturaMedium;
  font-weight: 300;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 2rem;

  ${(props) =>
    props.selected
      ? css`
          border-bottom: 1px solid rgb(180, 247, 200);
        `
      : css`
          border-bottom: none;
        `}
`

export const StyledLogo = styled.img`
  max-height: 75px;
  vertical-align: middle;
  /* margin-right: 5%; */
`

export const StyledAvatar = styled.img`
  max-height: 100px;
  margin-top: 15px;
  margin-right: 10%;
  border-radius: 25%;
  border: 40px solid rgb(180, 247, 200);
`

export const StyledUserInfo = styled.span`
  display: inline-block;
  overflow: hidden;
  /* border: 2px solid green;
  flex-direction: column;
  height: 100%; */
`
export const NavbarWrapper = styled.nav`
  color: white;
  background-color: rgb(53, 58, 69);
  margin-top: 2rem;
  margin-bottom: 2rem;
`
export const Alex = styled.div`
  width: 100%;
  display: flex;
  /* position: flex-end; */
  justify-content: right;
  margin-top: 1rem;
  padding-right: 2rem;
`
