import React from "react"

import AppointmentsTableAdmin from "../../components/AppointmentsTable/AppointmentsTableAdmin"
import CustomersTableAdmin from "../../components/CustomersTable/CustomersTableAdmin"
import AgentsTableAdmin from "../../components/AgentsTable/AgentsTableAdmin"

const AdminInfo = ({ user }) => {
  if (!user?.username) return <p>Error fetching agent info</p>

  return (
    <div>
      <AppointmentsTableAdmin user={user} />
      <CustomersTableAdmin user={user} />
      <AgentsTableAdmin user={user} />
    </div>
  )
}
export default AdminInfo
