import Modal from 'antd/lib/modal/Modal'
import { useState } from 'react'
import { Row, Col, Typography, Button, Form, Input, Popconfirm, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import axios from 'axios'
import { StyledButton } from '../../styles'

const { Text } = Typography

const CustomerDetailsModal = ({ customers, customerId, show = false, onClose, refetch }) => {
  const [showCreateQuestionModal, setShowCreateQuestionModal] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [alternativeInput, setAlternativeInput] = useState('')
  const [questionInput, setQuestionInput] = useState('')
  const [form] = Form.useForm()
  const customer = customers.find((item) => item.id === customerId)

  const onCloseQuestionModal = (values) => {
    onClose()
    setShowCreateQuestionModal(false)
    console.log('onCloseQuestionModal got: ' + values)
  }

  const deleteQuestion = (item) => {
    axios
      .delete(process.env.REACT_APP_API_URL + '/question', {
        headers: {
          'Content-Type': 'application/json',
          id: item?.id
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('deleteQuestion: response: ', response)
        return response.data
      })
      .finally(() => {
        refetch()
      })
      .catch((err) => console.error('deleteQuestion error: ', err.response?.data?.message, err.response))
  }

  const createQuestion = (data) =>
    axios
      .post(process.env.REACT_APP_API_URL + '/question', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('addQuestion: response: ', response)
        return response.data
      })
      .finally(() => {
        refetch()
      })
      .catch((err) => console.error('addQuestion error: ', err.response?.data?.message, err.response))

  const addQuestion = (questionText) => {
    if (questionText?.length < 2) return message.info('Question cannot be empty')
    const data = {
      question: questionText,
      customerId: customer.id
    }
    createQuestion(data)
  }

  const addQuestionWithAlternatives = (questionText) => {
    const data = {
      question: questionInput,
      alternatives,
      customerId: customer.id
    }
    console.log('addQuestionWithAlternatives got: ', data)
    createQuestion(data)
  }

  const ColumnData = ({ text, data }) =>
    !!data && (
      <>
        <Col span={8} style={{ marginBottom: '1rem' }}>
          <Text strong>{text}</Text>
        </Col>
        <Col span={16}>
          <Text keyboard>{data}</Text>
        </Col>
      </>
    )

  return (
    <Modal
      title={`Customer details (id #${customer.id}: ${customer?.companyName})`}
      visible={show}
      onOk={onClose}
      // confirmLoading={confirmLoading}
      onCancel={onClose}
      // bodyStyle={{ minHeight: '80vh' }}
      width="50vw"
      centered
      destroyOnClose
    >
      <Modal
        title={'Create multiple choice question)'}
        visible={showCreateQuestionModal}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields()
              addQuestionWithAlternatives(values)
              setShowCreateQuestionModal(false)
            })
            .catch((info) => {
              console.log('Validate Failed:', info)
            })
        }}
        // confirmLoading={confirmLoading}
        onCancel={onCloseQuestionModal}
        // bodyStyle={{ minHeight: '80vh' }}
        width="50vw"
        centered
        destroyOnClose
      >
        {/* <Form form={form} layout="vertical" name="form_in_modal"> */}
        <Input
          style={{ width: 'calc(100% - 200px)' }}
          placeholder="Write new question here..."
          defaultValue=""
          onChange={(e) => setQuestionInput(e?.target?.value)}
          value={questionInput}
        />
        <Input
          style={{ width: 'calc(100% - 200px)' }}
          placeholder="Write new answer choice here..."
          defaultValue=""
          onChange={(e) => setAlternativeInput(e?.target?.value)}
          value={alternativeInput}
        />
        {alternatives?.map?.((item) => (
          <p key={item}>
            <Popconfirm
              title="Are you sure to delete this item?"
              onConfirm={() => setAlternatives(alternatives.filter((alt) => alt !== item))}
              onCancel={() => console.log('Canceled')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small" shape="circle" danger icon={<DeleteOutlined />} />
            </Popconfirm>
            <Text italic> {item}</Text>
          </p>
        ))}

        <StyledButton
          type="primary"
          onClick={() => {
            setAlternativeInput('')
            setAlternatives((prev) => [...prev, alternativeInput])
          }}
        >
          Add
        </StyledButton>
        {/* </Form> */}
      </Modal>
      <Row gutter={8}>
        <Col span={24}>
          <Row gutter={8}>
            <ColumnData text="Company id:" data={customer.id} />
            <ColumnData text="Company name:" data={customer.companyName} />
            <ColumnData text="Org no.:" data={customer.companyOrgNumber} />
            <ColumnData text="Address:" data={customer.companyAddress} />
            <ColumnData text="Email:" data={customer.companyEmail} />
            <ColumnData text="Contact person:" data={customer.companyContactPerson} />
            <ColumnData text="Contact position:" data={customer.companyContactPosition} />
            <ColumnData text="Company note:" data={customer.companyNote} />
            <Text strong>Questions to be asked when booking meeting:</Text>
            <Col span={24} offset={1}>
              <div style={{ marginTop: '1rem' }}>
                {customer?.questions?.map((item) => (
                  <p key={item.id}>
                    Question: <Text italic> {item.question}</Text>
                    {item.alternatives && <div style={{ marginTop: '10px' }}>Alternatives:</div>}
                    <ul>
                      {item.alternatives?.map((alt) => (
                        <>
                          <li key={alt}>{alt}</li>
                        </>
                      ))}
                    </ul>
                    <Popconfirm
                      title="Are you sure to delete this question?"
                      onConfirm={() => deleteQuestion(item)}
                      onCancel={() => console.log('Canceled')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" size="small" shape="round" danger icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                    <hr />
                  </p>
                ))}
                <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 200px)' }}
                    placeholder="Write new question here..."
                    defaultValue=""
                    onChange={(e) => setQuestionInput(e?.target?.value)}
                    value={questionInput}
                  />
                  <Button type="primary" onClick={() => addQuestion(questionInput)}>
                    Add
                  </Button>
                </Input.Group>
                <Button type="primary" onClick={() => setShowCreateQuestionModal(true)}>
                  Add multiple choice question
                </Button>
                {/* <Row>
                    <Input type="text">Question</Input>
                    <Button
                      type="primary"
                      shape="round"
                      icon={<PlusOutlined />}
                      style={{ marginTop: '1rem' }}
                      onClick={() => setShowCreateQuestionModal(true)}
                    >
                      Add question
                    </Button>
                  </Row> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}
export default CustomerDetailsModal

// {
//   "id": 1,
//   "meetingDate": "2022-05-11T17:02:30.102Z",
//   "meetingTime": null,
//   "meetingIsDigital": null,
//   "meetingAddress": null,
//   "meetingNote": "Sales meeting 1",
//   "createdAt": "2022-05-11T17:02:30.102Z",
//   "updatedAt": "2022-05-11T17:02:30.102Z",
//   "vendorId": 1,
//   "customerId": 1,
//   "userId": 1,
//   "leadId": 1,
//   "user": {
//       "id": 1,
//       "username": "intercom",
//       "firstname": "Alexander",
//       "lastname": "Midteide",
//       "email": null,
//       "role": "AGENT",
//       "description": null,
//       "createdAt": "2022-05-11T17:02:30.100Z",
//       "updatedAt": "2022-05-11T17:02:30.100Z",
//       "vendorId": 1,
//       "customerId": null,
//       "vendor": {
//           "id": 1,
//           "companyName": "Teleperformance vendor1",
//           "companyOrgNumber": "1234",
//           "companyAddress": null,
//           "companyContactPerson": null,
//           "companyContactPosition": null,
//           "companyPhoneNumber": null,
//           "companyEmail": null,
//           "companyQuestion1": null,
//           "companyQuestion2": null,
//           "companyQuestion3": null,
//           "companyQuestion4": null,
//           "companyNote": null,
//           "createdAt": "2022-05-11T17:02:30.022Z",
//           "updatedAt": "2022-05-11T17:02:30.022Z",
//           "customers": [
//               {
//                   "id": 1,
//                   "companyName": "customer1",
//                   "companyOrgNumber": "1234",
//                   "companyAddress": null,
//                   "companyContactPerson": null,
//                   "companyContactPosition": null,
//                   "companyPhoneNumber": null,
//                   "companyEmail": null,
//                   "companyQuestion1": null,
//                   "companyQuestion2": null,
//                   "companyQuestion3": null,
//                   "companyQuestion4": null,
//                   "companyNote": null,
//                   "createdAt": "2022-05-11T17:02:30.025Z",
//                   "updatedAt": "2022-05-11T17:02:30.025Z",
//                   "vendorId": 1,
//                   "questions": [
//                       {
//                           "id": 1,
//                           "title": "Multiple questions test",
//                           "question": [
//                               "Spm 1",
//                               "Spm 2",
//                               "Spm 3"
//                           ],
//                           "type": "dropdown",
//                           "createdAt": "2022-05-11T17:02:30.105Z",
//                           "updatedAt": "2022-05-11T17:02:30.105Z",
//                           "customerId": 1,
//                           "answers": [
//                               {
//                                   "id": 1,
//                                   "answer": "Spm 1",
//                                   "createdAt": "2022-05-11T17:02:30.105Z",
//                                   "updatedAt": "2022-05-11T17:02:30.105Z",
//                                   "appointmentId": 1,
//                                   "questionId": 1
//                               }
//                           ]
//                       },
//                       {
//                           "id": 2,
//                           "title": "Text input test",
//                           "question": [
//                               "Hvilket land bor du i?"
//                           ],
//                           "type": "text",
//                           "createdAt": "2022-05-11T17:02:30.105Z",
//                           "updatedAt": "2022-05-11T17:02:30.105Z",
//                           "customerId": 1,
//                           "answers": [
//                               {
//                                   "id": 2,
//                                   "answer": "Norge",
//                                   "createdAt": "2022-05-11T17:02:30.105Z",
//                                   "updatedAt": "2022-05-11T17:02:30.105Z",
//                                   "appointmentId": 1,
//                                   "questionId": 2
//                               }
//                           ]
//                       }
//                   ]
//               },
//               {
//                   "id": 2,
//                   "companyName": "customer2",
//                   "companyOrgNumber": "5678",
//                   "companyAddress": null,
//                   "companyContactPerson": null,
//                   "companyContactPosition": null,
//                   "companyPhoneNumber": null,
//                   "companyEmail": null,
//                   "companyQuestion1": null,
//                   "companyQuestion2": null,
//                   "companyQuestion3": null,
//                   "companyQuestion4": null,
//                   "companyNote": null,
//                   "createdAt": "2022-05-11T17:02:30.025Z",
//                   "updatedAt": "2022-05-11T17:02:30.025Z",
//                   "vendorId": 1,
//                   "questions": []
//               }
//           ]
//       },
//       "customer": null
//   },
//   "vendor": {
//       "id": 1,
//       "companyName": "Teleperformance vendor1",
//       "companyOrgNumber": "1234",
//       "companyAddress": null,
//       "companyContactPerson": null,
//       "companyContactPosition": null,
//       "companyPhoneNumber": null,
//       "companyEmail": null,
//       "companyQuestion1": null,
//       "companyQuestion2": null,
//       "companyQuestion3": null,
//       "companyQuestion4": null,
//       "companyNote": null,
//       "createdAt": "2022-05-11T17:02:30.022Z",
//       "updatedAt": "2022-05-11T17:02:30.022Z",
//       "users": [
//           {
//               "id": 1,
//               "username": "intercom",
//               "firstname": "Alexander",
//               "lastname": "Midteide",
//               "email": null,
//               "role": "AGENT",
//               "description": null,
//               "createdAt": "2022-05-11T17:02:30.100Z",
//               "updatedAt": "2022-05-11T17:02:30.100Z",
//               "vendorId": 1,
//               "customerId": null,
//               "customer": null
//           }
//       ],
//       "customers": [
//           {
//               "id": 1,
//               "companyName": "customer1",
//               "companyOrgNumber": "1234",
//               "companyAddress": null,
//               "companyContactPerson": null,
//               "companyContactPosition": null,
//               "companyPhoneNumber": null,
//               "companyEmail": null,
//               "companyQuestion1": null,
//               "companyQuestion2": null,
//               "companyQuestion3": null,
//               "companyQuestion4": null,
//               "companyNote": null,
//               "createdAt": "2022-05-11T17:02:30.025Z",
//               "updatedAt": "2022-05-11T17:02:30.025Z",
//               "vendorId": 1,
//               "users": [
//                   {
//                       "id": 3,
//                       "username": "kunde",
//                       "firstname": "Einar",
//                       "lastname": "Brandsdal",
//                       "email": null,
//                       "role": "CLIENT",
//                       "description": null,
//                       "createdAt": "2022-05-11T17:02:30.100Z",
//                       "updatedAt": "2022-05-11T17:02:30.100Z",
//                       "vendorId": null,
//                       "customerId": 1
//                   }
//               ],
//               "questions": [
//                   {
//                       "id": 1,
//                       "title": "Multiple questions test",
//                       "question": [
//                           "Spm 1",
//                           "Spm 2",
//                           "Spm 3"
//                       ],
//                       "type": "dropdown",
//                       "createdAt": "2022-05-11T17:02:30.105Z",
//                       "updatedAt": "2022-05-11T17:02:30.105Z",
//                       "customerId": 1,
//                       "answers": [
//                           {
//                               "id": 1,
//                               "answer": "Spm 1",
//                               "createdAt": "2022-05-11T17:02:30.105Z",
//                               "updatedAt": "2022-05-11T17:02:30.105Z",
//                               "appointmentId": 1,
//                               "questionId": 1
//                           }
//                       ]
//                   },
//                   {
//                       "id": 2,
//                       "title": "Text input test",
//                       "question": [
//                           "Hvilket land bor du i?"
//                       ],
//                       "type": "text",
//                       "createdAt": "2022-05-11T17:02:30.105Z",
//                       "updatedAt": "2022-05-11T17:02:30.105Z",
//                       "customerId": 1,
//                       "answers": [
//                           {
//                               "id": 2,
//                               "answer": "Norge",
//                               "createdAt": "2022-05-11T17:02:30.105Z",
//                               "updatedAt": "2022-05-11T17:02:30.105Z",
//                               "appointmentId": 1,
//                               "questionId": 2
//                           }
//                       ]
//                   }
//               ]
//           },
//           {
//               "id": 2,
//               "companyName": "customer2",
//               "companyOrgNumber": "5678",
//               "companyAddress": null,
//               "companyContactPerson": null,
//               "companyContactPosition": null,
//               "companyPhoneNumber": null,
//               "companyEmail": null,
//               "companyQuestion1": null,
//               "companyQuestion2": null,
//               "companyQuestion3": null,
//               "companyQuestion4": null,
//               "companyNote": null,
//               "createdAt": "2022-05-11T17:02:30.025Z",
//               "updatedAt": "2022-05-11T17:02:30.025Z",
//               "vendorId": 1,
//               "users": [],
//               "questions": []
//           }
//       ]
//   },
//   "customer": {
//       "id": 1,
//       "companyName": "customer1",
//       "companyOrgNumber": "1234",
//       "companyAddress": null,
//       "companyContactPerson": null,
//       "companyContactPosition": null,
//       "companyPhoneNumber": null,
//       "companyEmail": null,
//       "companyQuestion1": null,
//       "companyQuestion2": null,
//       "companyQuestion3": null,
//       "companyQuestion4": null,
//       "companyNote": null,
//       "createdAt": "2022-05-11T17:02:30.025Z",
//       "updatedAt": "2022-05-11T17:02:30.025Z",
//       "vendorId": 1,
//       "users": [
//           {
//               "id": 3,
//               "username": "kunde",
//               "firstname": "Einar",
//               "lastname": "Brandsdal",
//               "email": null,
//               "role": "CLIENT",
//               "description": null,
//               "createdAt": "2022-05-11T17:02:30.100Z",
//               "updatedAt": "2022-05-11T17:02:30.100Z",
//               "vendorId": null,
//               "customerId": 1,
//               "vendor": null
//           }
//       ],
//       "questions": [
//           {
//               "id": 1,
//               "title": "Multiple questions test",
//               "question": [
//                   "Spm 1",
//                   "Spm 2",
//                   "Spm 3"
//               ],
//               "type": "dropdown",
//               "createdAt": "2022-05-11T17:02:30.105Z",
//               "updatedAt": "2022-05-11T17:02:30.105Z",
//               "customerId": 1,
//               "answers": [
//                   {
//                       "id": 1,
//                       "answer": "Spm 1",
//                       "createdAt": "2022-05-11T17:02:30.105Z",
//                       "updatedAt": "2022-05-11T17:02:30.105Z",
//                       "appointmentId": 1,
//                       "questionId": 1
//                   }
//               ]
//           },
//           {
//               "id": 2,
//               "title": "Text input test",
//               "question": [
//                   "Hvilket land bor du i?"
//               ],
//               "type": "text",
//               "createdAt": "2022-05-11T17:02:30.105Z",
//               "updatedAt": "2022-05-11T17:02:30.105Z",
//               "customerId": 1,
//               "answers": [
//                   {
//                       "id": 2,
//                       "answer": "Norge",
//                       "createdAt": "2022-05-11T17:02:30.105Z",
//                       "updatedAt": "2022-05-11T17:02:30.105Z",
//                       "appointmentId": 1,
//                       "questionId": 2
//                   }
//               ]
//           }
//       ],
//       "vendor": {
//           "id": 1,
//           "companyName": "Teleperformance vendor1",
//           "companyOrgNumber": "1234",
//           "companyAddress": null,
//           "companyContactPerson": null,
//           "companyContactPosition": null,
//           "companyPhoneNumber": null,
//           "companyEmail": null,
//           "companyQuestion1": null,
//           "companyQuestion2": null,
//           "companyQuestion3": null,
//           "companyQuestion4": null,
//           "companyNote": null,
//           "createdAt": "2022-05-11T17:02:30.022Z",
//           "updatedAt": "2022-05-11T17:02:30.022Z",
//           "users": [
//               {
//                   "id": 1,
//                   "username": "intercom",
//                   "firstname": "Alexander",
//                   "lastname": "Midteide",
//                   "email": null,
//                   "role": "AGENT",
//                   "description": null,
//                   "createdAt": "2022-05-11T17:02:30.100Z",
//                   "updatedAt": "2022-05-11T17:02:30.100Z",
//                   "vendorId": 1,
//                   "customerId": null
//               }
//           ]
//       }
//   },
//   "lead": {
//       "id": 1,
//       "companyName": "Equinor AS lead1",
//       "companyOrgNumber": "1234",
//       "companyAddress": null,
//       "companyContactPerson": null,
//       "companyContactPosition": null,
//       "companyPhoneNumber": null,
//       "companyEmail": null,
//       "companyQuestion1": null,
//       "companyQuestion2": null,
//       "companyQuestion3": null,
//       "companyQuestion4": null,
//       "companyNote": null,
//       "createdAt": "2022-05-11T17:02:30.102Z",
//       "updatedAt": "2022-05-11T17:02:30.102Z",
//       "vendorId": 1,
//       "vendor": {
//           "id": 1,
//           "companyName": "Teleperformance vendor1",
//           "companyOrgNumber": "1234",
//           "companyAddress": null,
//           "companyContactPerson": null,
//           "companyContactPosition": null,
//           "companyPhoneNumber": null,
//           "companyEmail": null,
//           "companyQuestion1": null,
//           "companyQuestion2": null,
//           "companyQuestion3": null,
//           "companyQuestion4": null,
//           "companyNote": null,
//           "createdAt": "2022-05-11T17:02:30.022Z",
//           "updatedAt": "2022-05-11T17:02:30.022Z",
//           "users": [
//               {
//                   "id": 1,
//                   "username": "intercom",
//                   "firstname": "Alexander",
//                   "lastname": "Midteide",
//                   "email": null,
//                   "role": "AGENT",
//                   "description": null,
//                   "createdAt": "2022-05-11T17:02:30.100Z",
//                   "updatedAt": "2022-05-11T17:02:30.100Z",
//                   "vendorId": 1,
//                   "customerId": null,
//                   "customer": null
//               }
//           ],
//           "customers": [
//               {
//                   "id": 1,
//                   "companyName": "customer1",
//                   "companyOrgNumber": "1234",
//                   "companyAddress": null,
//                   "companyContactPerson": null,
//                   "companyContactPosition": null,
//                   "companyPhoneNumber": null,
//                   "companyEmail": null,
//                   "companyQuestion1": null,
//                   "companyQuestion2": null,
//                   "companyQuestion3": null,
//                   "companyQuestion4": null,
//                   "companyNote": null,
//                   "createdAt": "2022-05-11T17:02:30.025Z",
//                   "updatedAt": "2022-05-11T17:02:30.025Z",
//                   "vendorId": 1,
//                   "users": [
//                       {
//                           "id": 3,
//                           "username": "kunde",
//                           "firstname": "Einar",
//                           "lastname": "Brandsdal",
//                           "email": null,
//                           "role": "CLIENT",
//                           "description": null,
//                           "createdAt": "2022-05-11T17:02:30.100Z",
//                           "updatedAt": "2022-05-11T17:02:30.100Z",
//                           "vendorId": null,
//                           "customerId": 1
//                       }
//                   ],
//                   "questions": [
//                       {
//                           "id": 1,
//                           "title": "Multiple questions test",
//                           "question": [
//                               "Spm 1",
//                               "Spm 2",
//                               "Spm 3"
//                           ],
//                           "type": "dropdown",
//                           "createdAt": "2022-05-11T17:02:30.105Z",
//                           "updatedAt": "2022-05-11T17:02:30.105Z",
//                           "customerId": 1,
//                           "answers": [
//                               {
//                                   "id": 1,
//                                   "answer": "Spm 1",
//                                   "createdAt": "2022-05-11T17:02:30.105Z",
//                                   "updatedAt": "2022-05-11T17:02:30.105Z",
//                                   "appointmentId": 1,
//                                   "questionId": 1
//                               }
//                           ]
//                       },
//                       {
//                           "id": 2,
//                           "title": "Text input test",
//                           "question": [
//                               "Hvilket land bor du i?"
//                           ],
//                           "type": "text",
//                           "createdAt": "2022-05-11T17:02:30.105Z",
//                           "updatedAt": "2022-05-11T17:02:30.105Z",
//                           "customerId": 1,
//                           "answers": [
//                               {
//                                   "id": 2,
//                                   "answer": "Norge",
//                                   "createdAt": "2022-05-11T17:02:30.105Z",
//                                   "updatedAt": "2022-05-11T17:02:30.105Z",
//                                   "appointmentId": 1,
//                                   "questionId": 2
//                               }
//                           ]
//                       }
//                   ]
//               },
//               {
//                   "id": 2,
//                   "companyName": "customer2",
//                   "companyOrgNumber": "5678",
//                   "companyAddress": null,
//                   "companyContactPerson": null,
//                   "companyContactPosition": null,
//                   "companyPhoneNumber": null,
//                   "companyEmail": null,
//                   "companyQuestion1": null,
//                   "companyQuestion2": null,
//                   "companyQuestion3": null,
//                   "companyQuestion4": null,
//                   "companyNote": null,
//                   "createdAt": "2022-05-11T17:02:30.025Z",
//                   "updatedAt": "2022-05-11T17:02:30.025Z",
//                   "vendorId": 1,
//                   "users": [],
//                   "questions": []
//               }
//           ]
//       }
//   },
//   "answers": [
//       {
//           "id": 1,
//           "answer": "Spm 1",
//           "createdAt": "2022-05-11T17:02:30.105Z",
//           "updatedAt": "2022-05-11T17:02:30.105Z",
//           "appointmentId": 1,
//           "questionId": 1,
//           "question": {
//               "id": 1,
//               "title": "Multiple questions test",
//               "question": [
//                   "Spm 1",
//                   "Spm 2",
//                   "Spm 3"
//               ],
//               "type": "dropdown",
//               "createdAt": "2022-05-11T17:02:30.105Z",
//               "updatedAt": "2022-05-11T17:02:30.105Z",
//               "customerId": 1
//           }
//       },
//       {
//           "id": 2,
//           "answer": "Norge",
//           "createdAt": "2022-05-11T17:02:30.105Z",
//           "updatedAt": "2022-05-11T17:02:30.105Z",
//           "appointmentId": 1,
//           "questionId": 2,
//           "question": {
//               "id": 2,
//               "title": "Text input test",
//               "question": [
//                   "Hvilket land bor du i?"
//               ],
//               "type": "text",
//               "createdAt": "2022-05-11T17:02:30.105Z",
//               "updatedAt": "2022-05-11T17:02:30.105Z",
//               "customerId": 1
//           }
//       }
//   ]
// }
