import counterReducer from './counter'
import loggedReducer from './isLogged'
import { combineReducers } from 'redux'
import userReducer from './user'
import userInputsReducer from './userInputs'
import appvariablesReducer from './appvariables'

const allReducers = combineReducers({
  counter: counterReducer,
  isLogged: loggedReducer,
  user: userReducer,
  userInputs: userInputsReducer,
  appvariables: appvariablesReducer
})

export default allReducers
