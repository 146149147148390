import React, { memo, useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Tooltip, Select, DatePicker, ConfigProvider, Checkbox } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import {
  handleCreateAppointment,
  useCustomers,
  useLeadsInfoByUser,
  useQuestionsByCustomerId,
  useVendorInfoByUser
} from '../../fetchHooks/useFetchHooks'
import moment from 'moment-timezone'
import { PlusOutlined } from '@ant-design/icons'
import CreateLeadForm from '../../CreateLead/CreateLeadForm'
import 'moment/locale/en-gb'
import locale from 'antd/es/locale/en_GB'
import { StyledButton, StyledLabel, StyledTextArea, StyledTextInput } from '../../styles'
import './CreateAppointment.scss'
import { ModalButtons } from '../CreateAgent/CreateAgentForm'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})
function range(start, end) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

const disabledDateTime = () => {
  return {
    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23], // () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(0, 0),
    disabledSeconds: () => range(0, 0)
  }
}

const disabledDate = (current) => current && current < moment().endOf('day')

const CreateAppointment = memo(({ onOk, onClose, showCreateAppointmentModal, user }) => {
  const [form] = Form.useForm()
  const [createLeadModalvisible, setCreateLeadModalVisible] = useState(false)
  const [isDigital, setIsDigital] = useState(true)
  const { data: vendorInfo, isLoading, error } = useVendorInfoByUser(user)
  const { data: leadsInfo, refetch: refetchLeads } = useLeadsInfoByUser(user)
  const { data: questions, refetch: refetchQuestions } = useQuestionsByCustomerId(form.getFieldValue(['customerId']))

  useEffect(() => {
    form.resetFields()
  }, [form])
  const { data: customers } = useCustomers()
  if (!showCreateAppointmentModal) return <></>

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error...</p>

  const onCreateLead = (values) => {
    console.log('onCreateLeadReceived values of form: ', values)
    const data = {
      ...values,
      vendorId: user.vendorId,
      username: user.username
    }
    axios
      .post(process.env.REACT_APP_API_URL + '/leads', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Create Lead: response: ', response)
        return response.data
      })
      .finally(() => {
        refetchLeads()
        setCreateLeadModalVisible(false)
      })
      .catch((err) => console.error('Signin error: ', err.response?.data?.message, err.response))
  }

  const onFinish = (values) => {
    if (values.customerId) {
      questions?.map((item, index) => (item.answer = values['question' + index]))
      const data = {
        ...values,
        meetingDate: values.meetingDate?.clone?.().tz('Europe/Oslo'),
        meetingIsDigital: isDigital,
        vendorId: user.vendorId,
        userId: user.id,
        questions,
        customer: vendorInfo?.customers?.find((item) => item.id === form.getFieldValue(['customerId'])),
        lead: leadsInfo?.find((item) => item.id === form.getFieldValue(['leadId'])),
        vendor: vendorInfo,
        user
      }
      console.log('Received values of form:', data)
      handleCreateAppointment(data, user, onOk)
    }
  }

  return (
    <Modal
      title="Create appointment"
      visible={showCreateAppointmentModal}
      // bodyStyle={{ minHeight: '80vh' }}
      onCancel={onClose}
      bodyStyle={{ minHeight: '50vh', padding: 0, borderRadius: '20px' }}
      className="modalStyle"
      width="720px"
      footer={null}
      centered
      destroyOnClose
    >
      <Form
        // labelCol={{
        //   span: 14
        // }}
        // wrapperCol={{
        //   span: 14
        // }}
        form={form}
        layout="vertical"
        initialValues={{
          size: 'default',
          meetingLength: 60
          // meetingDate: moment().clone().tz('Europe/Oslo'),
          // meetingIsDigital: false,
          // customerId: vendorInfo?.customers.slice(-1)?.id
        }}
        //   onValuesChange={onFormLayoutChange}
        size={'default'}
        onFinish={onFinish}
      >
        <CreateLeadForm visible={createLeadModalvisible} onCreate={onCreateLead} onCancel={() => setCreateLeadModalVisible(false)} />
        <div className="create-appointment-container">
          <div className="create-appointment-header">
            <h2>Create new meeting </h2>
          </div>

          <div className="create-appointment-title ">
            <h2>Please fill in the following information:</h2>
          </div>
          <div className="create-appointment-customer ">
            <Form.Item
              name="customerId"
              label={<StyledLabel>Select which company the meeting is being booked for</StyledLabel>}
              // label="Select which company the meeting is being booked for"
              rules={[
                {
                  required: true,
                  message: 'Please select which company you are booking this meeting for'
                }
              ]}
            >
              <Select
                showSearch
                onChange={(e) => refetchQuestions()}
                placeholder="Select a customer"
                optionFilterProp="children"
                dropdownStyle={{
                  backgroundColor: 'white',
                  color: 'rgb(53, 58, 69)',
                  paddingLeft: '8px',
                  borderRadius: '10px',
                  border: '1px solid rgb(53, 58, 69)'
                }}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {customers?.map((customer) => (
                  <Select.Option value={customer.id} key={customer.id}>
                    {customer.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="create-appointment-lead">
            <Form.Item
              name="leadId"
              label={<StyledLabel>Select company meeting is being booked with</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please select which company you are booking this meeting with'
                }
              ]}
            >
              <Select
                showSearch
                placeholder="Select a lead"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {leadsInfo?.map((lead) => (
                  <Select.Option value={lead.id} key={lead.id}>
                    {lead.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="create-appointment-addleadbtn">
            <Tooltip title="Add new lead">
              <StyledButton
                type="primary"
                // style={{ marginTop: '30px' }}
                // style={{ alignSelf: 'flex-end', marginBottom: '25px' }}
                className="addleadbtn"
                shape="round"
                size="default"
                onClick={() => setCreateLeadModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Add
              </StyledButton>
            </Tooltip>
          </div>
          <div className="create-appointment-date">
            <ConfigProvider locale={locale}>
              <Form.Item
                name="meetingDate"
                label={<StyledLabel>Date and Time</StyledLabel>}
                rules={[
                  {
                    required: true,
                    message: 'Please choose meeting date and time'
                  }
                ]}
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  placeholder="Select date / time"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                  showTime={{
                    initialValue: moment('08:00', 'HH:mm'),
                    minuteStep: 15,
                    format: 'HH:mm',
                    allowClear: false,
                    hideDisabledOptions: true,
                    showNow: false
                  }}
                />
              </Form.Item>
            </ConfigProvider>
          </div>
          <div className="create-appointment-meetinglength">
            <Form.Item
              name="meetingLength"
              label={<StyledLabel>Meeting length</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please choose meeting length'
                }
              ]}
            >
              <Select
                // showSearch
                // addonBefore="Lead company"

                placeholder="Select duration"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Select.Option value={30}>30 min</Select.Option>
                <Select.Option value={60}>60 min</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="create-appointment-digital">
            <Form.Item name="meetingIsDigital" className="collection-create-form_last-form-item">
              <>
                <Checkbox checked={isDigital} onChange={(e) => setIsDigital(e.target.checked)}>
                  <StyledLabel>Digital meeting</StyledLabel>
                </Checkbox>
                <Checkbox checked={!isDigital} onChange={(e) => setIsDigital((prev) => !prev)}>
                  <StyledLabel>Physical meeting</StyledLabel>
                </Checkbox>
              </>
            </Form.Item>
          </div>
          <Questions questions={questions} />
          <div className="create-appointment-note">
            <Form.Item
              name="meetingNote"
              label={<StyledLabel>Meeting note</StyledLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please write a meeting note'
                }
              ]}
            >
              <StyledTextArea rows={4} />
            </Form.Item>
          </div>
          <ModalButtons
            onOk={form.submit}
            // confirmLoading={confirmLoading}
            onCancel={onClose}
          />
        </div>
      </Form>
    </Modal>
  )
})

const Questions = ({ questions }) => {
  return (
    <>
      {questions?.length && (
        <div className="create-appointment-questions">
          <h2>Questions: </h2>

          {questions?.map((item, index) => {
            console.log(item)
            return (
              <div key={item.id}>
                <Form.Item name={'question' + index} label={<StyledLabel>{item.question}</StyledLabel>} style={{ marginTop: '1rem' }}>
                  {/* {item.type === 'dropdown' ? ( */}
                  {item.alternatives?.length ? (
                    <Select
                      placeholder="Select answer"
                      dropdownStyle={{
                        backgroundColor: 'white',
                        color: 'rgb(53, 58, 69)',
                        paddingLeft: '8px',
                        borderRadius: '10px',
                        border: '1px solid rgb(53, 58, 69)'
                      }}
                    >
                      {item?.alternatives?.map((alternative, index) => (
                        <Select.Option value={alternative} key={alternative + index}>
                          {alternative}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <StyledTextInput />
                  )}
                </Form.Item>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default CreateAppointment
