import React, { useEffect, useState } from 'react'
import { Button, Space, Tooltip, Table } from 'antd'
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { deleteUser, useAgentsByVendorId, useAppointmentsInfoByVendorId } from '../../fetchHooks/useFetchHooks'
import { LinkButton } from '../../pages/HomePage/styles'
import { StyledButton, TableContainer } from '../../styles'
import CreateAgentForm from '../CreateAgent/CreateAgentForm'
import Popconfirm from 'antd/es/popconfirm'

const AgentsTableAdmin = ({ user }) => {
  const [showAgentDetailsModal, setShowAgentDetailsModal] = useState(false)
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false)
  const { data: agents, isLoading, isError, refetch } = useAgentsByVendorId(user)
  const { data: appointments } = useAppointmentsInfoByVendorId(user?.vendorId)

  useEffect(() => {
    agents && console.log('AgentsTableAdmin agents: ', agents)
  }, [agents])

  if (isLoading) return 'Loading...'
  if (isError) return 'Error...'

  const columns =
    !appointments || !agents
      ? []
      : [
          {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id,
            key: 'id',
            render: (id, item) => <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{id}</LinkButton>
          },
          {
            title: 'Username',
            dataIndex: 'username',
            sorter: (a, b) => a.username.localeCompare(b.username),
            key: 'username',
            render: (username, item) => <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{`${username} `}</LinkButton>
          },
          {
            title: 'Name',
            dataIndex: 'firstname',
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            key: 'companyName',
            render: (firstname, item) => (
              <LinkButton onClick={() => setShowAgentDetailsModal(item.id)}>{`${firstname} ${item.lastname}`}</LinkButton>
            )
          },
          {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a, b) => a.role.localeCompare(b.role),
            key: 'role',
            render: (role, customer) => <div>{role}</div>
          },
          {
            title: 'Total booked',
            dataIndex: 'customerId',
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            key: 'customerId',
            render: (customerId, customer) => <div>{agents.filter((item) => item.agentId === customer.id).length}</div>
          },
          {
            title: 'Won',
            dataIndex: 'customerId',
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            key: 'customerId',
            render: (customerId, customer) => (
              <div>{appointments.filter((item) => item.feedback?.meetingStatus === 'Won' && item.customerId === customer.id).length}</div>
            )
          },
          {
            title: 'Lost',
            dataIndex: 'customerId',
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            key: 'customerId',
            render: (customerId, customer) => (
              <div>{appointments.filter((item) => item.feedback?.meetingStatus === 'Lost' && item.customerId === customer.id).length}</div>
            )
          },
          {
            title: 'Postponed',
            dataIndex: 'customerId',
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            key: 'customerId',
            render: (customerId, customer) => (
              <div>
                {appointments.filter((item) => item.feedback?.meetingStatus === 'Postponed' && item.customerId === customer.id).length}
              </div>
            )
          },

          {
            title: 'Action',
            key: 'action',
            render: (text, item) => (
              <Space size="middle">
                <Button
                  type="primary"
                  shape="round"
                  size="default"
                  icon={<EyeOutlined />}
                  onClick={() => setShowAgentDetailsModal(item.id)}
                  ghost
                  className="edit-button"
                />
                <Tooltip title="Delete appointment">
                  <Popconfirm
                    title="Are you sure to delete this item?"
                    onConfirm={() => deleteUser(item.id, refetch)}
                    onCancel={() => console.log('Canceled')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <StyledButton type="danger" shape="round" size="default" icon={<DeleteOutlined />} ghost className="delete-button" />
                  </Popconfirm>
                </Tooltip>
              </Space>
            )
          }
        ]
  if (isLoading) return <h1>Loading...</h1>

  return (
    <TableContainer>
      <h2>Agents:</h2>
      <Table dataSource={agents.filter((user) => user.role === 'AGENT')} columns={columns} rowKey="id" />

      <Tooltip title="Add new agent">
        <StyledButton type="primary" shape="round" size="default" onClick={() => setShowCreateAgentModal(true)} icon={<PlusOutlined />}>
          Add agent
        </StyledButton>
      </Tooltip>

      <CreateAgentForm
        visible={showCreateAgentModal}
        onCreate={() => {
          setShowCreateAgentModal(false)
          refetch()
        }}
        onCancel={() => setShowCreateAgentModal(false)}
        vendorId={user.vendorId}
      />
    </TableContainer>
  )
}

export default AgentsTableAdmin
