import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { storeAppvariables } from '../../redux/actions'
import { useNavigate } from 'react-router-dom'
import { CenteredContent } from '../../styles'
import { LoginWrapper, RoundedInput, StyledSwitch, StyledButton } from './styles'
import { Form, Image, Row, Col, Switch } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import CookieConsent from 'react-cookie-consent'
import SalesPlanrLogo from '../../images/SalesPlanrLogo.png'
import { envApiUrl } from '../../fetchHooks/useFetchHooks'

const Login = () => {
  const dispatch = useDispatch()
  const appVariables = useSelector((state) => state.appvariables)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const handleRegistration = (formdata) => {
    const data = { ...formdata, username: formdata.username.toLowerCase() }
    console.log(data)
    axios
      .post(envApiUrl + '/auth/signin', data, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
        xsrfCookieName: 'csrftoken_testtest',
        xsrfHeaderName: 'X-CSRFToken'
      })
      .then((response) => {
        console.log('Login: response: ', response)
        if (response?.data?.loggedIn) {
          dispatch(storeAppvariables('user', response.data?.user))
          navigate('/')
          window.location.reload()
          return response.data.user
        } else {
          console.log('Login: failed: ', response?.data?.message)
          setError(response?.data?.message)
        }
      })
      .catch((err) => console.error('Signin error: ', err.response?.data?.message, err.response))
  }

  useEffect(() => {
    console.log('Login: appVariables now: ', appVariables)
  }, [appVariables])

  return (
    <LoginWrapper>
      <Row justify={'center'} align="middle" gutter={[100, 100]}>
        <Col span={12}>
          <Image src={SalesPlanrLogo} preview={false} />
        </Col>
      </Row>
      <Row justify={'center'} align="middle" gutter={[100, 100]}>
        <Col span={12}>
          <CenteredContent>
            <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={handleRegistration}>
              <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                <RoundedInput size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                <RoundedInput
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {error && (
                <CenteredContent>
                  <span style={{ color: 'red' }}>{error}</span>
                </CenteredContent>
              )}
              <CenteredContent>
                <Form.Item name="remember" noStyle>
                  <StyledSwitch>
                    <Switch />
                    <span style={{ color: 'white', marginLeft: '1rem' }}>Remember me</span>
                  </StyledSwitch>
                </Form.Item>
                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item> */}
              </CenteredContent>

              <CenteredContent>
                <Form.Item>
                  <StyledButton size="large" shape="round" type="primary" htmlType="submit">
                    Log in
                  </StyledButton>
                </Form.Item>
              </CenteredContent>
            </Form>
          </CenteredContent>
        </Col>
      </Row>
      <CookieConsent
        location="bottom"
        cookieName="SalesPlanrCookieConsent"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </LoginWrapper>
  )
}

export default Login
